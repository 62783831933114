import React from 'react';
import styled from 'styled-components';


function Loading() {
  return (
    <DivContainer>
      <img src="/assets/images/loading.gif" alt="loading" />
    </DivContainer>
  );
}

export default Loading;

const DivContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(21, 27, 41, .3);
  z-index: 10000;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
