import {
  SET_USERS,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

const defaultState = {
  users: [],
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  };
};

export default reducer;
