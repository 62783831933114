import axios from 'axios'
import environment from '../environments/environment.dev';

const API = axios.create({ baseURL: environment.api_url });

export const getMessages = (id) => API.get(`/message/${id}`);

export const addMessage = (data) => API.post('/message/', data);

export const getUserUnreadMessages = (chatId, senderId) => API.get(`/unread-message/${chatId}/${senderId}`);

export const updateMessageRead = (chatId) => API.get(`/update-read-message/${chatId}`);

export const getTotalUnreadMessages = (userId) => API.get(`/get-total-unread-message/${userId}`);