import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { stateMappings } from "../redux/stateMappings";
import { myweerdGets, weerdEdit } from "../redux/weerd/thunks";
import { setToast } from "../lib/toast";
import { removeToken, getToken } from "../lib/apiRequest";

import moment from "moment";

const currentUser = getToken("user");

class MyWeerd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeWeerd: this.props.weerds,
      user: currentUser,
    };
  }

  componentDidMount() {
    this.props.gets();
  }

  componentDidUpdate(prevProps, prevStates) {
    if (this.props.weerds.length !== prevProps.weerds.length) {
      
      this.setState({
        activeWeerd: this.props.weerds
          //.filter((e) => e.author._id === currentUser._id)
          .sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }),
      });
    }
  }

  render() {
    return (
      <SectionContainer className="py-24 sm:py-12">
        <div className="container-smart">
          <h2 className="fw-ebold mb-8 ">My Weerds</h2>

          <table width={1200} border="1" cellPadding={4} cellSpacing={4}>
            <thead>
              <tr>
                <th style={{"text-align":"center"}}>Title</th>
                <th style={{"text-align":"center"}}>Category</th>
                <th style={{"text-align":"center"}}>Likes</th>
                <th style={{"text-align":"center"}}>Dislikes</th>
                <th style={{"text-align":"center"}}>Reports</th>
                <th style={{"text-align":"center"}}>Shares</th>
                <th style={{"text-align":"center"}}>Views</th>
                <th style={{"text-align":"center"}}>Created On</th>
              </tr>
            </thead>
            <tbody>
              {this.state.activeWeerd.map((item, i) => (
                <tr>
                  <td style={{"text-align":"center"}}><div className="fw-ebold focus-editing" name="main-weerd" dangerouslySetInnerHTML={{__html: item.title}} readOnly></div></td>
                  <td style={{"text-align":"center"}}>{item.category}</td>
                  <td style={{"text-align":"center"}}>{item.likes.length}</td>
                  <td style={{"text-align":"center"}}>{item.dislikes.length}</td>
                  <td style={{"text-align":"center"}}>{item.rights.length}</td>
                  <td style={{"text-align":"center"}}>{item.shares.length}</td>
                  <td style={{"text-align":"center"}}>{item.total_views}</td>
                  <td style={{"text-align":"center"}}>{moment(item.createdAt).format("LL")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SectionContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    weerds: sm.weerds || [],
    currentUser: sm.user || {},
    loading: sm.loading,
  };
};

const mapActionsToProps = {
  gets: myweerdGets,
};

export default connect(mapStateToProps, mapActionsToProps)(MyWeerd);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`;
