import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import WeerdPayPalModal from "./WeerdPayPalModal";
import { setWeerdyModal } from "../redux/modal/thunks";
import { useSelector, useDispatch } from "react-redux";
import { stateMappings } from "../redux/stateMappings";

import { weerdCreate } from "../redux/weerd/thunks";
import environment from "../environments/environment.dev";

Modal.setAppElement("#root");

const WeerdPremiumModal = ({
  open,
  isOpen,
  customStyles,
  weerdChar,
  weerd,
}) => {
  const [options, setOptions] = useState({ option15: false, option30: false });
  const [payable, setPayable] = useState(0);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const weerdPayPalModalOpen = stateMappings(state).weerdPayPalModalOpen;

  const purchaseHandler = async () => {
    const timeCost = options.option30 ? 4 : options.option15 ? 2 : 0;
    const charCost = (Math.ceil(weerdChar / 15) - 1) * 2;
    if (timeCost + charCost > 0) {
      setPayable(timeCost + charCost);
      dispatch(setWeerdyModal("weerdPayPalModalOpen"));
    } else {
      try {
        await dispatch(
          weerdCreate({
            ...weerd,
            time: 15,
          })
        );
        window.location.href = `${environment.base_url}/`;
      } catch (error) {
        return;
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={(e) => open(e, "weerdPremiumModalOpen")}
        closeTimeoutMS={500}
        style={customStyles}
        className="modal fade show"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content onboardingModal">
            <div className="row">
              <div className="col-lg-12 ">
                <h5 className="fw-bold text-center mb-2">
                  Select Your Subscription Plan
                </h5>
                <ul className="lh-1-6 fs-14">
                  <li>
                    -15 more seconds for Weerd display:{" "}
                    <span className="color-green">$1.99</span>
                  </li>
                  <li>
                    -30 more seconds for Weerd display:{" "}
                    <span className="color-green">
                      $1.99 (after purchase of +15)
                    </span>
                  </li>
                  <li>
                    -15 more characters for Weerds:{" "}
                    <span className="color-green">$1.99</span>
                  </li>
                  <li>
                    -30 more characters for Weerds:{" "}
                    <span className="color-green">
                      $1.99 (after purchase of +15)
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 my-auto text-center">
                <img
                  className="ee-image"
                  src="/assets/images/ee-image.png"
                  alt="image"
                />
              </div>
              <DivContainer className="col-lg-6 col-md-6 col-sm-12">
                <form className="subscription-plan options-box">
                  <div
                    className={`relative${options.option15 ? " checked" : ""}`}
                  >
                    <input
                      type="radio"
                      name="purchaseable_plan"
                      id="15-seconds"
                      value="+15 Seconds"
                    />
                    <label
                      className="button red w-100 radius border-black playSound"
                      htmlFor="15-seconds"
                      onClick={() => {
                        setOptions((prev) => {
                          const newOptions = prev.option15
                            ? { option15: false, option30: false }
                            : { option15: true, option30: false };
                          return newOptions;
                        });
                      }}
                    >
                      <span>+15 seconds </span>/ $1.99
                    </label>
                    <i className="fa fa-check"></i>
                  </div>
                  <div
                    className={`relative${options.option30 ? " checked" : ""}`}
                  >
                    <input
                      type="radio"
                      name="purchaseable_plan"
                      id="30-seconds"
                      value="+30 Seconds"
                    />
                    <label
                      className="button red w-100 radius border-black playSound"
                      htmlFor="30-seconds"
                      onClick={() => {
                        setOptions((prev) => {
                          const newOption30 = prev.option15
                            ? !prev.option30
                            : false;
                          return { ...prev, option30: newOption30 };
                        });
                      }}
                    >
                      <span>+30 seconds </span>/ $3.99{" "}
                      
                    </label>
                    <i className="fa fa-check"></i>
                  </div>
                  <div
                    className={`relative${weerdChar > 15 ? " checked" : ""}`}
                  >
                    <input
                      type="radio"
                      name="purchaseable_plan"
                      id="15-characters"
                      value="+15 characters "
                    />
                    <label
                      className="button red w-100 radius border-black playSound"
                      htmlFor="15-characters"
                    >
                      <span>+15 characters </span>/ $1.99
                    </label>
                    <i className="fa fa-check"></i>
                  </div>
                  <div
                    className={`relative${weerdChar > 30 ? " checked" : ""}`}
                  >
                    <input
                      type="radio"
                      name="purchaseable_plan"
                      id="30-characters"
                      value="+30 characters"
                    />
                    <label
                      className="button red w-100 radius border-black playSound"
                      htmlFor="30-characters"
                    >
                      <span>+30 words </span> / $3.99{" "}
                      
                    </label>
                    <i className="fa fa-check"></i>
                  </div>
                  <button
                    data-toggle="modal"
                    data-dismiss="modal"
                    data-target="#PurchaseablePaymentMethodModal"
                    type="button"
                    className="button w-100 radius border-black mt-4 "
                    onClick={purchaseHandler}
                  >
                    Purchase
                  </button>
                </form>
              </DivContainer>
            </div>
          </div>
        </div>
      </Modal>
      {weerdPayPalModalOpen && (
        <WeerdPayPalModal
          open={open}
          isOpen={weerdPayPalModalOpen}
          customStyles={customStyles}
          amount={payable}
          time={options.option30 ? 45 : 30}
          weerd={weerd}
        />
      )}
    </>
  );
};

export default WeerdPremiumModal;

const DivContainer = styled.div`
  .relative {
    margin-top: 1.5rem;
  }
`;
