import {
  SIGNIN_USER,
  SIGNOUT_USER,
  EDIT_USER,
  REGISTER_USER,
  DELETE_USER,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE
} from './actionEnum';

export const registerUser = (response) => ({
  type: REGISTER_USER,
  payload: response
});

export const signInUser = (response) => ({
  type: SIGNIN_USER,
  payload: response,
});

export const editUser = (response) => ({
  type: EDIT_USER,
  payload: response,
});

export const deleteUser = (response = {}) => ({
  type: DELETE_USER,
  payload: response,
});

export const signOutUser = (response = {}) => ({
  type: SIGNOUT_USER,
  payload: response,
});

export const setLoadingStatus = (candidate) => ({
  type: SET_LOADING_STATUS,
  payload: candidate,
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: message,
});


