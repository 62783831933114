import { getTvUsers } from '../redux/admin/thunks';


const setupDataManager = () => async (dispatch, getState) => {
  await Promise.all([
    dispatch(getTvUsers()),
  ]);
};

export default setupDataManager;
