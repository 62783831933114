import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';

import { stateMappings } from '../redux/stateMappings';
import { signIn } from '../redux/auth/thunks';

class Donate extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <section className="py-24 sm:py-12">
        <div className="container-smart">
          <div className="row row-eq-height">
            <div className="col-lg-4 col-md-4 col-sm-12 column">
              <div className="box-card text-center relative">
                <div className="v-center detail">
                  <img className="ee-image" src="/assets/images/donate-image.png" alt="donate" />
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 sm:mt-8 column">
              <div className="box-card weerdy-detail">
                  <h3 className="fw-ebold mb-8 text-center">We greatly appreciate your donation</h3>
                  <div className="donation">
                    <ul>
                      <li className="button radius border-black">$50</li>
                      <li className="button radius border-black">$100</li>
                      <li className="button radius border-black">$250</li>
                      <li className="button radius border-black">$500</li>
                      <li className="button radius border-black">$1000</li>
                      <li className="button radius border-black">$2000</li>
                      <li className="label my-auto">Other Amount:</li>
                      <li>
                        <form>
                          <div className="input-group radius border-black">
                            <div className="input-group-prepend">
                              <span className="input-group-text bgcolor-green color-white b-0 fw-bold">$</span>
                            </div>
                            <input id="donation_input" value="" type="text" className="form-control bgcolor-green color-white b-0" placeholder=". . . ." aria-label="amount" />
                          </div>
                        </form>
                      </li>
                      <li></li>
                    </ul>
                  <div className="text-center mt-8">
                    <div className="text-center"><button className="btn_bg sm b-0 cursor"><span>DONATE</span></button></div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  signIn
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Donate);

