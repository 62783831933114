import { combineReducers } from "redux";

import auth from "./auth/reducer";
import video from "./video/reducer";
import admin from './admin/reducer';
import modal from './modal/reducer';
import weerd from './weerd/reducer';

const rootReducer = combineReducers({
  auth,
  video,
  admin,
  modal,
  weerd,
});

export default rootReducer;
