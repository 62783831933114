import {
  SET_WEERDS,
  SET_MY_WEERDS,
  ADD_WEERD,
  EDIT_WEERD,
  REMOVE_WEERD,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
  GET_WEERD
} from './actionEnum';

const defaultState = {
  weerds: [],
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {
    case SET_WEERDS:
      return { ...state, weerds: action.payload };

  case SET_MY_WEERDS:
    return { ...state, weerds: action.payload };     

    case ADD_WEERD:
      return { ...state, weerds: [
        ...state.weerds,
        action.payload,
      ] };

    case EDIT_WEERD:
      let weerds = state.weerds;
      const index = weerds.findIndex(weerd => action.payload._id === weerd._id);
      weerds[index] = action.payload;

      return { ...state, weerds };

    case REMOVE_WEERD:
      return { ...state, weerds:
          state.weerds.filter(weerd => weerd._id !== action.payload) }

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
	  
	case GET_WEERD:
		return { ...state, payload: action.payload};

    default:
      return state;
  };
};

export default reducer;
