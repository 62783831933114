import { SET_MODAL } from "./actionEnum";

const defaultState = {
  signInModalOpen: false,
  signUpModalOpen: false,
  termsModalOpen: false,
  premiumModalOpen: false,
  createConfirmModalOpen: false,
  usernameEditModalOpen: false,
  passwordChangeModalOpen: false,
  weerdPremiumModalOpen: false,
  weerdPayPalModalOpen: false,
  paymentModalOpen: false,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...defaultState,
        [action.payload]: !state[action.payload],
      };

    default:
      return state;
  }
};

export default reducer;
