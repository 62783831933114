import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";

import { stateMappings } from "../redux/stateMappings";
import { setToast } from "../lib/toast";
import { userEdit } from "../redux/auth/thunks";
import { APIRequest } from '../lib/apiRequest';

import environment from "../environments/environment.dev";

const CreatWeerdForm = ({amount,time,weerd}) => {
	//alert(weerd.anonymous_payable);
	const stripe = useStripe();
	const elements = useElements();
	const state = useSelector((state) => state);
	const user = stateMappings(state).user;
	const [loading, setLoading] = useState(false);
	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);
		
		const res = await axios.post("https://weerdy-api.herokuapp.com/api/weerd", {
		  amount: amount,
		  time: time,
		  title: weerd.title,
		  category: weerd.category,
		  author: weerd.author,
		  email: user.email,
		  is_stripe: 1
		});
		const clientSecret = res.data["client_secret"];
		
		const result = await stripe.confirmCardPayment(clientSecret, {
		  payment_method: {
			card: elements.getElement(CardElement),
			billing_details: {
			  email: user.email,
			},
		  },
		});
		if (result.error) {
		  setToast("error", result.error.message);
		  setLoading(true);
		} else {
			if (result.paymentIntent.status === "succeeded") {
				APIRequest.request({
				method: 'POST',
				url: `/weerd`,
				data: {
				  author: weerd.author,
				  title: weerd.title,
				  category: weerd.category,
				  time: time,
				  is_anonymous: weerd.anonymous_payable ? true : false
				}
				}).then((result) => {
					if(!weerd.anonymous_payable) {
						APIRequest.request({
							method: 'POST',
							url: `/send-notification`,
							data: {
							  author: weerd.author,
							  weerdId: result.data._id
							}
						});
					}
					setToast("success", "Payment successful!");
					window.location.href = `${environment.base_url}`;
				});
			}
		}
	}
	
	return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements || loading}>
        Pay
      </button>
    </form>
	);
}

const CheckoutForm = ({ plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const state = useSelector((state) => state);
  const user = stateMappings(state).user;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const res = await axios.post("https://weerdy-api.herokuapp.com/api/pay", {
      email: user.email,
      amount: plan === "year" ? Math.round(199.99*100) : Math.round(19.99*100),
    });
    const clientSecret = res.data["client_secret"];

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          email: user.email,
        },
      },
    });

    if (result.error) {
      
      setToast("error", result.error.message);
      setLoading(true);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // post a plan for this user
        let now = new Date();
        dispatch(
          userEdit({
            ...user,
            //username: "ahmed",
            plan: "premium",
            plan_expires:
              plan === "year"
                ? new Date(now.setMonth(now.getMonth() + 12))
                : new Date(now.setMonth(now.getMonth() + 1)),
          })
        ).then(()=> {
			  //window.location.href = `${environment.base_url}/profile`;
			  setToast("success", "your account has been updated!");
		  });
      } else {
        setLoading(true);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements || loading}>
        Pay
      </button>
    </form>
  );
};

const closeModal = () => {
	window.location.reload();
}

Modal.setAppElement("#root");

const StripePyamentModal = ({ open, isOpen, customStyles, plan, amount, time, weerd }) => {
  const stripePromise = loadStripe(
    "pk_live_51J0WxRDcKm6ZidcNeYQFGCZLuYiqTEozqmyf7yKwJiAVpfmZM4UxY4571KNr6OSEJ9J3ze4uSGENfzoSiTWi0k6m00WXopn9v1"
  );
  /*const stripePromise = loadStripe(
    "pk_test_5Lm2sZNsKZQapPmQvEFKP7Cf00Pew0POK1"
  );*/

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={(e) => open(e, "StripePyamentModalOpen")}
      closeTimeoutMS={500}
      style={customStyles}
      className="modal fade show"
    >
      <div
        className="modal-dialog modal-md modal-dialog-centered"
        role="document"
      >
        <div className="modal-content onboardingModal">
          <div className="row">
            <div className="col-lg-12 mb-8 text-center">
              <h5 className="fw-bold">Select Your Payment Method</h5>
			  <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12  my-auto text-center sm:hidden">
              <img
                className="ee-image"
                src="/assets/images/ee-image.png"
                alt="image"
              />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              <Elements stripe={stripePromise}>
				{
					(weerd) ? <CreatWeerdForm amount={amount} time={time} weerd={weerd} /> :
                <CheckoutForm plan={plan} />
				}
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StripePyamentModal;

const DivContainer = styled.div`
  .relative {
    margin-top: 1.5rem;
  }
`;
