import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import TermsModal from "./TermsModal";
import PremiumModal from "./PremiumModal";
import CreateConfirmModal from "./CreateConfirmModal";
import UserNameEditModal from "./UserNameEditModal";
import PasswordChangeModal from "./PasswordChangeModal";
import WeerdPremiumModal from "./WeerdPremiumModal";
import PaymentModal from "./PaymentModal";
import StripePyamentModal from "./StripePyamentModal";
import { signOut } from "../redux/auth/thunks";
import { setWeerdyModal } from "../redux/modal/thunks";
import { stateMappings } from "../redux/stateMappings";
import { APIRequest } from '../lib/apiRequest';
import Badge from "./Badge";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileMenuOpen: false,
      notifications: false,
	  weerdNotification: []
    };
  }

  componentDidMount() {
	  const { user } = this.props;
	  try {
			APIRequest.request({
				method: 'GET',
				url: `/weerd-notification/`+user._id
			}).then((result) => {
				this.setState({weerdNotification: result.data.weerdNotifications});
			})
	  } catch (error) {
			console.log(error.message);
	  }
  }

  openModal = (event, name = "signInModalOpen") => {
    this.props.setWeerdyModal(event.target.name || name);
  };

  showMobileMenu = () => {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen,
    });
  };

  showNotifications = () => {
    this.setState({
      notifications: !this.state.notifications,
    });
  };

  render() {
    const { isMobileMenuOpen, notifications, weerdNotification } = this.state;
    const {
      isAuthenticated,
      user,
      children,
      signOut,
      signInModalOpen,
      signUpModalOpen,
      termsModalOpen,
      premiumModalOpen,
      createConfirmModalOpen,
      usernameEditModalOpen,
      passwordChangeModalOpen,
      weerdPremiumModalOpen,
      paymentModalOpen,
      StripePyamentModalOpen,
    } = this.props;
    return (
      <Fragment>
        <header className={`bgcolor-blue${isMobileMenuOpen ? " open" : ""}`}>
          <div className="container">
            <div className="row p-3 top-header">
              <div className="logo">
                <h1>
                  <a href="/">
                    {" "}
                    <img src="/assets/images/logo.svg" alt="Logo" />
                  </a>
                </h1>
              </div>
              <div className="row sm:hidden">
                <div className="col search my-auto">
                  <form>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search here..."
                    />
                  </form>
                </div>
                <div className="col my-auto">
                  <div className="user-actions">
                    <ul>
                      {isAuthenticated && this.props.user.plan == 'premium' &&  (
                        <Badge currentUser={this.props.user._id} />
                      )}                         
                      <li className="hover-blue">
                        <a href="/">
                          <i className="fa fa-home"></i>
                        </a>
                      </li>
                      <li className="hover-blue">
                        <a href="/settings">
                          <i className="fa fa-cog"></i>
                        </a>
                      </li>
                      <li className="hover-blue position-relative">
                        <a
                          href="#"
                          onClick={this.showNotifications}
                          className="notifications-icon"
                        >
                          <i className="fa fa-bell"></i> <span>{weerdNotification.slice(0, 10).length}</span>
                        </a>
                        <ul
                          className={`notifications${
                            notifications ? " active" : ""
                          }`}
                        >
						  {weerdNotification.slice(0, 10).map((lis, index) => (
                          <li key={index}>
                            <a href={"https://weerdy.com/weerd?text="+lis._id}>
                              <strong>{lis.author.username}</strong> posted a new weerd{" "}
                            </a>
                          </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="menu-icon my-auto hidden md:show">
                <img
                  className=""
                  src="/assets/images/menu.svg"
                  alt="menu-icon"
                  onClick={this.showMobileMenu}
                />
              </div>
            </div>
          </div>
          <div
            id="lgMenu"
            className={`sidebar-wrapper p-3${isMobileMenuOpen ? " enter" : ""}`}
          >
            <div className="content">
              <div className="row">
                <div className="col my-auto p-0">
                  <a href="/">
                    <img src="/assets/images/logo.svg" alt="Logo" />
                  </a>
                </div>
                <div className="col text-right my-auto p-0">
                  <img
                    className="exit"
                    src="/assets/images/exit.svg"
                    onClick={this.showMobileMenu}
                    alt="exit-icon"
                  />
                </div>
              </div>
              <div className="col search my-auto p-0 my-8">
                <form>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="Search here..."
                  />
                </form>
              </div>
              <div className="user-actions">
                <ul>
                 {isAuthenticated && this.props.user.plan == 'premium' && (
                    <Badge currentUser={this.props.user._id} />
                  )}    
                  <li className="hover-blue">
                    <a href="/">
                      <i className="fa fa-home"></i>
                    </a>
                  </li>
                  <li className="hover-blue">
                    <a href="/settings">
                      <i className="fa fa-cog"></i>
                    </a>
                  </li>
                  <li className="hover-blue position-relative">
                    <a href="#" className="notifications-icon">
                      <i className="fa fa-bell"></i> <span>4</span>
                    </a>
                    <ul className="notifications">
                      <li>
                        <a href="#">
                          <strong>James</strong> posted a new weerd{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <strong>Juliana Soter</strong>Message you{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <strong>James</strong> posted a new weerd{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <strong>Juliana Soter</strong>Message you{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="mobile-nav mt-8">
                <ul className="fw-ebold color-black">
                  {true && (
                    <li>
                      <a href="/create">Create a Weerd</a>
                    </li>
                  )}
                  {true && (
                    <li>
                      <a href="/myweerd">My Weerd</a>
                    </li>
                  )}
                  {true && (
                    <li>
                      <a href="/categories">Weerdy Categories</a>
                    </li>
                  )}
                  {true && (
                    <li>
                      <a href="/profile">Profile</a>
                    </li>
                  )}
                  {true && (
                    <li>
                      <a href="/day">Weerds of the Day</a>
                    </li>
                  )}
                  {user.plan !== 'premium' ? 
                    <li>
                      <a
                        href="#"
                        onClick={this.openModal}
                        name="premiumModalOpen"
                      >
                        Premium
                      </a>{" "}
                    </li>
                  : ""}
                  <li>
                    <a href="/explained">Weerdy Explained</a>{" "}
                  </li>
                  <li>
                    <a href="/donate">Donate</a>
                  </li>
                  <li>
                    <a href="#" onClick={this.openModal} name="termsModalOpen">
                      Terms
                    </a>
                  </li>
                  {true && (
                    <Fragment>
                      <li>
                        <a
                          href="#"
                          onClick={this.openModal}
                          name="signInModalOpen"
                        >
                          Log In
                        </a>{" "}
                        /{" "}
                        <a onClick={this.openModal} name="signUpModalOpen">
                          Sign Up
                        </a>
                      </li>
                    </Fragment>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </header>
        <div className="bgcolor-gray p-3 md-hidden position-sticky">
          <div className="container">
            <div className="col-lg-12 my-auto">
              <nav>
                <ul className="navigation fw-ebold color-black">
                  {true && (
                    <li>
                      <a href="/create">Create a Weerd</a>
                    </li>
                  )}

                  {true && (
                    <li>
                      <a href="/categories">Weerdy Categories</a>
                    </li>
                  )}
                  {true && (
                    <li>
                      <a href="/profile">Profile</a>
                    </li>
                  )}
                  {true && (
                    <li>
                      <a href="/day">Weerds of the Day</a>
                    </li>
                  )}
                  {isAuthenticated && user.plan !== 'premium' ? 
                    <li>
                      <a
                        href="#"
                        onClick={this.openModal}
                        name="premiumModalOpen"
                      >
                        Premium
                      </a>{" "}
                    </li>
                  : ""}
                  <li>
                    <a href="/explained">Weerdy Explained</a>{" "}
                  </li>
                  <li>
                    <a href="/donate">Donate</a>
                  </li>
                  <li>
                    <a href="#" onClick={this.openModal} name="termsModalOpen">
                      Terms
                    </a>
                  </li>
                  {isAuthenticated && (
                    <li>
                      <a href="/myweerd">My Weerd</a>
                    </li>
                    
                  )}
               
                  {!isAuthenticated && (
                    <Fragment>
                      <li>
                        <a
                          href="#"
                          onClick={this.openModal}
                          name="signInModalOpen"
                        >
                          Log In
                        </a>{" "}
                        /{" "}
                        <a
                          href="#"
                          onClick={this.openModal}
                          name="signUpModalOpen"
                        >
                          Sign Up
                        </a>
                      </li>
                    </Fragment>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <LoginModal
          open={this.openModal}
          isOpen={signInModalOpen}
          customStyles={customStyles}
        />
        <RegisterModal
          open={this.openModal}
          isOpen={signUpModalOpen}
          customStyles={customStyles}
        />
        <TermsModal
          open={this.openModal}
          isOpen={termsModalOpen}
          customStyles={customStyles}
        />
        <PremiumModal
          open={this.openModal}
          isOpen={premiumModalOpen}
          customStyles={customStyles}
        />
        <CreateConfirmModal
          open={this.openModal}
          isOpen={createConfirmModalOpen}
          customStyles={customStyles}
        />
        <UserNameEditModal
          open={this.openModal}
          isOpen={usernameEditModalOpen}
          customStyles={customStyles}
        />
        <PasswordChangeModal
          open={this.openModal}
          isOpen={passwordChangeModalOpen}
          customStyles={customStyles}
        />
        <WeerdPremiumModal
          open={this.openModal}
          isOpen={weerdPremiumModalOpen}
          customStyles={customStyles}
        />
        {/* <PaymentModal open={this.openModal} isOpen={paymentModalOpen} customStyles={customStyles} /> */}
        {children}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);
  return {
    user: sm.user,
    isAuthenticated: sm.isAuthenticated,
    signInModalOpen: sm.signInModalOpen,
    signUpModalOpen: sm.signUpModalOpen,
    termsModalOpen: sm.termsModalOpen,
    premiumModalOpen: sm.premiumModalOpen,
    createConfirmModalOpen: sm.createConfirmModalOpen,
    usernameEditModalOpen: sm.usernameEditModalOpen,
    passwordChangeModalOpen: sm.passwordChangeModalOpen,
    weerdPremiumModalOpen: sm.weerdPremiumModalOpen,
    paymentModalOpen: sm.paymentModalOpen,
    StripePyamentModalOpen: sm.StripePyamentModalOpen,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
};

export default connect(mapStateToProps, mapActionsToProps)(Layout);

const DivContainer = styled.div``;
