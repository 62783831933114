import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastsContainer, ToastsStore } from "react-toasts";

import Loading from "./components/Loading";
import setupDataManager from "./dataManager";
import Router from "./Router";
import Layout from "./components/Layout";
import { stateMappings } from "./redux/stateMappings";
import "./App.scss";
import Timer from "./components/Timer";

class App extends Component {
  componentDidMount() {
    const {
      setupDataManager,
      isAuthenticated,
      // user,
      // isAdmin
    } = this.props;

    if (isAuthenticated) {
      // setupDataManager();
    }
  }
  render() {
    return (
      <div className="App">
        <Timer />
        <ToastsContainer store={ToastsStore} position="top_right" />
        {this.props.loading && <Loading />}
        <Layout>
          <Router />
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);
  return {
    loading: sm.loading,
    user: sm.user,
    isAdmin: sm.isAdmin,
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  setupDataManager,
};

export default connect(mapStateToProps, mapActionsToProps)(App);
