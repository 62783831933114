import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import {getWeerd} from "../redux/weerd/thunks";

const DailyWeerds = (props) =>
{
  const query = window.location.search.substring(1);
  var vars = query.split("&");
  var pair = vars[0].split("=");
  const {get,stp} = props;
  const likes = (stp.likes) ? stp.likes.length : 0;
  const dislikes = (stp.dislikes) ? stp.dislikes.length : 0;
  const rights = (stp.rights) ? stp.rights.length : 0;
  const shares = (stp.shares) ? stp.shares.length : 0;
  const is_premium = ((stp.author) && (stp.author.plan === 'premium')) ? <img className="badge_img" src="/assets/images/badge.svg" alt="badge" /> : '';
  useEffect(()=> {
	get(pair[1]);
  },[get]);
  console.warn(stp);
  
  return (
    <section>
      <div className="container">
        <div className="row ">
          <div className="col-lg-2"></div>
            <div className="col-lg-8 md:mt-8">
              <div className="row mb-8">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <a href="?t=most-liked"  className="button green w-100  d-block radius border-black mt-4 " style={(pair[1] === undefined || pair[1] === 'most-liked') ? {color: "red"} : {color: "white"}}>Most Liked </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <a href="?t=most-shared"  className="button green w-100  d-block radius border-black mt-4 " style={(pair[1] === 'most-shared') ? {color: "red"} : {color: "white"}}>Most Shared </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <a href="?t=most-viewed"  className="button green w-100  d-block radius border-black mt-4 " style={(pair[1] === 'most-viewed') ? {color: "red"} : {color: "white"}}>Most Viewed </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <a href="?t=most-disliked"  className="button green w-100  d-block radius border-black mt-4 " style={(pair[1] === 'most-disliked') ? {color: "red"} : {color: "white"}}>Most Disliked </a>
                </div>
              </div>
                <div className="border-box text-center border-black radius-xl">
				{is_premium}
				  {stp.author ?
                  <p className="fw-bold border-bottom-black color-twitter py-2">{!stp.author.is_anonymous ? <a href={"user-profile/?user="+(stp.author || {})._id}>@{
                        stp.author.username
                      }</a> : 'Anonymous'}</p> : ""}
					<div className="fw-ebold focus-editing" name="main-weerd" dangerouslySetInnerHTML={{__html: stp.title}} style={{height: "150px"}} readOnly></div>
                </div>
                <div className="reactions mt-16 mb-4 sm:my-8">
                  <ul>
                    <li><img className="hvr-up" src="/assets/images/thumbs-up.png" alt="Like" /> <p className="likes">{likes}</p></li>
                    <li><img className="hvr-down" src="/assets/images/thumbs-down.png" alt="Dislike" /> <p className="dislikes">{dislikes}</p></li>
                    <li className="position-relative"><img className="hvr-right" src="/assets/images/alarm.png" alt="Report" />
                      <i className="fa fa-info-circle" aria-hidden="true" data-toggle="popover" data-placement="top" data-content="Please use the whistle to report anything that is lewd, offensive, dangerous, or incities voilence."></i>
                      <p className="reports">{rights}</p>
                    </li>
                    <li><img className="hvr-up" src="/assets/images/telegram.png" alt="Share" /><p className="share">{shares}</p></li>
                  </ul>
                </div>
                
            </div>
            <div className="col-lg-2"></div>
        </div>
        <div className="container-smart pb-16 md:py-12">
          <div className="row">
              <p className="total-reactions "><span className="fs-poppins fw-bold" id="result">{stp.total_views}</span> <img className="v-middle" src="/assets/images/visible.png" alt="Eye-icon" /></p>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
	return {
		stp: state._newReduxTree.weerd.payload || []
	}
}

const mapActionsToProps = {
	get: getWeerd
}

export default connect(mapStateToProps, mapActionsToProps)(DailyWeerds);

