import React,{useEffect,useState} from 'react';
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal';

import { APIRequest } from '../lib/apiRequest';
import { stateMappings } from "../redux/stateMappings";
import { weerdGets, weerdEdit } from "../redux/weerd/thunks";
import { setToast } from "../lib/toast";

const Weerd = (props) =>
{
  var [title, setTitle] = useState("");
  var [authorid, setAuthorId] = useState("");
  var [show, setShow] = useState(false);
  var [totalLikes, setTotalLikes] = useState([]);
  var [totalDisikes, setTotalDislikes] = useState([]);
  var [totalRights, setTotalRights] = useState([]);
  var [totalShares, setTotalShares] = useState([]);
  const query = window.location.search.substring(1);
  var vars = query.split("&");
  var pair = vars[0].split("=");
  
  useEffect(()=> {
	try {
		APIRequest.request({
			method: 'GET',
			url: `/get-weerd/`+pair[1],
		}).then(result => {
			setTitle(result.data.title);
			setAuthorId(result.data.author);
			setTotalLikes(result.data.likes);
			setTotalDislikes(result.data.dislikes);
			setTotalRights(result.data.rights);
			setTotalShares(result.data.shares);
		});
	} catch (error) {
		console.log(error.message);
	}
  },[]);
  
  const handleClose = () => {
	  setShow(false);
  }

  const handleShow = (title) => {
	  const { currentUser } = props;
	  if (authorid === currentUser._id) {
        setToast("info", "This is your weerd");
        return;
      }
	  
	  if(totalShares.includes(currentUser._id)) {
		  return;
	  }
	  
	  if(totalDisikes.includes(currentUser._id)) {
		return;
	  }
	  if(totalRights.includes(currentUser._id)) {
		return;
	  }
	
	  setShow(true);
  }
  
  const actions = (type) => {
	  const { weerds, edit, currentUser, isAuthenticated } = props;
	  
	  if (!isAuthenticated) {
		  setToast("warning", "You should login first to do this action.");
		  return;
		}
	  
	  if (authorid === currentUser._id) {
        setToast("info", "This is your weerd");
        return;
      }
	  
	  if(totalLikes.includes(currentUser._id)) {
		if(type === 'dislike') {
			return;
		}
		
		if(type === 'right') {
			return;
		}
	}
	if(totalDisikes.includes(currentUser._id)) {
		if(type === 'like') {
			return;
		}
		
		if(type === 'share') {
			return;
		}
	}
	if(totalRights.includes(currentUser._id)) {
		if(type === 'like') {
			return;
		}
		
		if(type === 'share') {
			return;
		}
	}
	if(totalShares.includes(currentUser._id)) {
		if(type === 'dislike') {
			return;
		}
		
		if(type === 'right') {
			return;
		}
	}
	  
	  if (type === 'like' && totalLikes.includes(currentUser._id)) {
        setToast('info', `You already selected like`);
        return;
	  }
	  
	  if (type === 'dislike' && totalDisikes.includes(currentUser._id)) {
        setToast('info', `You already selected dislike`);
        return;
	  }
	  
	  if (type === 'right' && totalRights.includes(currentUser._id)) {
        setToast('info', `You already report this weerd`);
        return;
	  }
	  
	  if (type === 'share' && totalShares.includes(currentUser._id)) {
        setToast('info', `You already shared this weerd`);
        return;
	  }
	  
	 APIRequest.request({
		method: 'PUT',
		url: `/weerd/${pair[1]}`,
		data: {
		  total_views: 0,
		  [type]: currentUser._id,
		  type: type,
		}
	  }).then(() => {
		  if(type !== 'share') {
			setToast("success", "Success!");
			window.location.reload(false);
		  }
	  });
	  console.log(currentUser._id);
  }
  const { isAuthenticated } = props;
  return (
    <section>
      <div className="container">
        <div className="row ">
          <div className="col-lg-2"></div>
            <div className="col-lg-8 md:mt-8">
                <div className="border-box text-center border-black radius-xl">
					<div className="fw-ebold focus-editing" name="main-weerd" dangerouslySetInnerHTML={{__html: title}} style={{height: "150px"}} readOnly></div>
                </div>
            </div>
            <div className="col-lg-2"></div>
        </div>
      </div>
	  
	  <div className="reactions mt-16 mb-4 sm:my-8">
                      <ul>
                        <li onClick={() => actions("like")}>
                          <img
                            className="hvr-up"
                            src="/assets/images/thumbs-up.png"
                            alt="Like"
                          />{" "}
                          <p className="likes">
                            {totalLikes.length}
                          </p>
                        </li>
                        <li onClick={() => actions("dislike")}>
                          <img
                            className="hvr-down"
                            src="/assets/images/thumbs-down.png"
                            alt="Dislike"
							disabled={true}
                          />{" "}
                          <p className="dislikes">
                            {totalDisikes.length}
                          </p>
                        </li>
                        <li
                          onClick={() => actions("right")}
                          className="position-relative"
                        >
                          <img
                            className="hvr-right"
                            src="/assets/images/alarm.png"
                            alt="Report"
                          />
                          <p className="reports">
						  {totalRights.length}
                          </p>
                        </li>
                        <li onClick={() => actions("share")}>
                          <img onClick={() => isAuthenticated ? handleShow(title) : ""}
                            className="hvr-up"
                            src="/assets/images/telegram.png"
                            alt="Share"
                          />
                          <p className="share">
                            {totalShares.length}
                          </p>
                        </li>
                      </ul>
                    </div>
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Share</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<a href={"https://www.facebook.com/sharer/sharer.php?u=https://weerdy.com/weerd?text="+pair[1]} target="_blank"><img src="./assets/images/facebook.png" /></a>
			<a href={"https://twitter.com/intent/tweet?url=https://weerdy.com/weerd?text="+pair[1]} target="_blank"><img src="./assets/images/twitter.png" /></a>
			<a href={"https://api.whatsapp.com/send?text=https://weerdy.com/weerd?text="+pair[1]} target="_blank"><img src="./assets/images/whatsapp.png" /></a>
			</Modal.Body>
		</Modal>
    </section>
  );
}

const mapStateToProps = (state, ownProps) => {
  const sm = stateMappings(state);
  // const query = new URLSearchParams(ownProps.location.search);
  const categoryName =
    // query.get('category');
    decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent("category").replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  return {
    isAuthenticated: sm.isAuthenticated,
    weerds: (sm.weerds || []).filter(
      ({ category }) => !categoryName || category === categoryName
    ),
    currentUser: sm.user || {},
    loading: sm.loading,
	counter: sm.counter,
    ads: sm.ads,
    adActive: sm.adActive,
    activeAd: sm.activeAd
  };
};

const mapActionsToProps = {
  gets: weerdGets,
  edit: weerdEdit,
};

export default connect(mapStateToProps, mapActionsToProps)(Weerd);


