import {
  SET_USERS,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE
} from './actionEnum';

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: users
});

export const setLoadingStatus = (candidate) => ({
  type: SET_LOADING_STATUS,
  payload: candidate,
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: message,
});


