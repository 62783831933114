import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import environment from '../../environments/environment.dev';
import { getUser } from "../../api/UserRequests";
import { getUserUnreadMessages } from "../../api/MessageRequests";

const Conversation = ({ data, currentUser, online }) => {

  const [userData, setUserData] = useState(null);
  const [userUnreadMessageData, setUserUnreadMessageData] = useState([]);
  const dispatch = useDispatch()

  useEffect(()=> {

    const userId = data.members.find((id)=>id!==currentUser)
    const getUserData = async ()=> {
      try
      {
          const {data} =await getUser(userId)
         setUserData(data)
         dispatch({type:"SAVE_USER", data:data})
      }
      catch(error)
      {
        console.log(error)
      }
    }

    getUserData();
  }, [])


  useEffect(()=> {

    const senderId = data.members.find((id)=>id!==currentUser)
    const chatId = data._id;

    const getUserMessageUnreadData = async ()=> {
      try
      {
          const {data} =await getUserUnreadMessages(chatId, senderId)
          setUserUnreadMessageData(data)
      }
      catch(error)
      {
        console.log(error)
      }
    }

    getUserMessageUnreadData();
  }, [])


    return (
    <>
        <div className="img-sec">
            <img src={userData?.profile_img? environment.img_api+"public/userImages/"+ userData.profile_img : environment.img_api+"public/images/defaultProfile.png"} alt={userData?.username} className="followerImage" />
            {online && <div className="status-dot online"></div>}
        </div>        
        <div className="name">
            <span>
                {userData?.username} 
                <i className="msg-count" id={userData?._id} style={{display: userUnreadMessageData.length?"inline":"none"}}>{userUnreadMessageData.length}</i>
            </span>
            <span style={{color: online?"#51e200":""}}>{online? "Online" : "Offline"}</span>
        </div>
    </>
  );
};

export default Conversation;
