const environment = {
  production: false,
  base_url: "",
  api_url: "https://weerdyapilive.herokuapp.com/api",
  //api_url: "http://localhost:5000/api",
  //img_api: "http://localhost:5000/",
  img_api: "https://weerdyapilive.herokuapp.com/",
};

export default environment;
