import {
  SET_ALL_VIDEOS,
  SET_VIDEOS,
  SET_SHARED_VIDEOS,
  SET_ALL_COMMENTS,
  ADD_VIDEO,
  ADD_COMMENT,
  EDIT_VIDEO,
  REMOVE_VIDEO,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from './actionEnum';

const defaultState = {
  tvVideos: [],
  videos: [],
  sharedVideos: [],
  comments: [],
  loading: false,
  error: '',
}

const reducer = (state = defaultState, action) => {

  switch (action.type) {
    case SET_ALL_VIDEOS:
      return { ...state, tvVideos: action.payload };

    case SET_VIDEOS:
      return { ...state, videos: action.payload };

    case SET_SHARED_VIDEOS:
      return { ...state, sharedVideos: action.payload };

    case SET_ALL_COMMENTS:
      return { ...state, comments: action.payload };

    case ADD_VIDEO:
      return { ...state, videos: [
        ...state.videos,
        action.payload,
      ] };

    case ADD_COMMENT:
      return { ...state, comments: [
        ...state.comments,
        action.payload,
      ] };

    case EDIT_VIDEO:
      let videos = state.videos;
      const index = videos.findIndex(video => action.payload._id === video._id);
      videos[index] = action.payload;

      return { ...state, videos };

    case REMOVE_VIDEO:
      return { ...state, videos:
          state.videos.filter(video => video._id !== action.payload) }

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  };
};

export default reducer;
