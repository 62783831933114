import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { stateMappings } from "../redux/stateMappings";
import { userEdit } from "../redux/auth/thunks";

const Timer = () => {
  const state = useSelector((state) => state);
  const { isAuthenticated, user } = stateMappings(state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.plan === "premium") {
      var now = new Date();
      var then = new Date(user.plan_expires);
      var timeOut = Math.max(then - now, 1000); // if date is in the past settimout in 1 second
      timeOut = Math.min(timeOut, 0x7fffffff); // if date exceeds timout limit (32bit int) change limit
      console.log("membership expires on", timeOut, user.plan_expires);

      const timer = setTimeout(() => {
        console.log("changing user plan");
        dispatch(userEdit({ ...user, plan: "free", plan_expires: "" }));
      }, timeOut);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isAuthenticated, dispatch, user]);
  return <></>;
};

export default Timer;
