import React, {useState} from "react";
import Modal from "react-modal";
import { setToast } from "../lib/toast";

import { useDispatch,connect } from "react-redux";
import { weerdCreate } from "../redux/weerd/thunks";
import environment from "../environments/environment.dev";
import { stateMappings } from "../redux/stateMappings";
import { setWeerdyModal } from "../redux/modal/thunks";
import StripePyamentModal from "./StripePyamentModal";

import ReactDOM from "react-dom";

const PayPalButton = window.paypal.Buttons.driver("react", {
  React,
  ReactDOM,
});
Modal.setAppElement("#root");

const WeerdPayPalModal = ({
  open,
  isOpen,
  customStyles,
  amount,
  weerd,
  time,
  setModal,
},props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: "test",
          amount: { currency_code: "USD", value: amount },
        },
      ],application_context: {
                      shipping_preference: 'NO_SHIPPING'
                  },
    });
  };
  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      dispatch(
        weerdCreate({
          ...weerd,
          time,
        })
      ).then(()=> {
		  window.location.href = `${environment.base_url}/`;
		  setToast("success", "Payment successful!");
	  });
      //window.location.href = `${environment.base_url}/`;

      //const name = details.payer.name.given_name;
      //setToast("success", `Transaction completed by ${name}`);
    });
  };

  const closeModal = () => {
	  isOpen = false;
	  console.log(isOpen);
  }

  const StripePyamentModalOpens = () => {
	  setShow(true);
  }
  //const { StripePyamentModalOpen } = props;
  
  return (
  <>
    <Modal
      isOpen={isOpen}
      onRequestClose={(e) => open(e, "weerdPayPalModalOpen")}
      closeTimeoutMS={500}
      style={customStyles}
      className="modal fade show"
    >
      <div
        className="modal-dialog modal-md modal-dialog-centered"
        role="document"
      >
        <div className="modal-content onboardingModal">
          <div className="row">
            <div className="col-lg-12 ">
			
              <h5 className="fw-bold text-center mb-2">
                Select Payment Method
              </h5>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 my-auto text-center">
              <img
                className="ee-image"
                src="/assets/images/ee-image.png"
                alt="image"
              />
            </div>
			<div className="col-lg-6 col-md-6 col-sm-12 my-auto text-center">
			<form className="pay-methods">
			<div
			  className="relative checked"
			  onClick={StripePyamentModalOpens}
			>
			  <label
				className="button white w-100 radius border-black"
				htmlFor="master-card"
				style={{fontSize: "15px"}}
			  >
				Credit Card/Debit Card
			  </label>
			  <img src="/assets/images/mc.svg" alt="" />
			</div>
			</form>
			<div className="relative">
            <PayPalButton
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
            />
			</div>
			</div>
          </div>
        </div>
      </div>
    </Modal>
	
	<StripePyamentModal
		  open={open}
          isOpen={show}
		  amount={amount}
          time={time}
		  weerd={weerd}
        />
	
	</>
  );
};

const mapStateToProps = (state) => {
  const sm = stateMappings(state);
  console.log(sm);
  return {
    isAuthenticated: sm.isAuthenticated,
    StripePyamentModalOpen: sm.StripePyamentModalOpen,
    user: sm.user,
  };
};
const mapActionsToProps = {
  setModal: setWeerdyModal
};
export default connect(mapStateToProps, mapActionsToProps)(WeerdPayPalModal);
//export default WeerdPayPalModal;
