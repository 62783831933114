import React, { Component } from "react";
import { connect } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { APIRequest } from '../lib/apiRequest';
import { stateMappings } from "../redux/stateMappings";
import { setWeerdyModal } from "../redux/modal/thunks";
import { setToast } from "../lib/toast";
import environment from "../environments/environment.dev";
import WeerdPayPalModal from "../components/WeerdPayPalModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
  },
};

const Quill = ReactQuill.Quill
// Add fonts to whitelist
let Font = Quill.import('formats/font');
// We do not add Sans Serif since it is the default
Font.whitelist = ["arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"];
Quill.register(Font, true);

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      isCreate: false,
      categorySelect: false,
      category: "",
      categories: [
        "General",
        "Politics",
        "Sports",
        "Technology",
        "Fashion",
        "Gaming",
        "Food",
        "Travel",
        "Art",
        "Education",
        "Science",
        "Comedy",
        "Entertainment",
        "Movies",
        "Gossip",
        "Finance",
      ],
	  banned_words: [
		"fuck",
		'"fuck"',
		'"fuck',
		'"fuck,',
		"fuck,",
		",fuck",
		",fuck,",
		"fuck!",
		"'fuck'",
		"'fuck",
		"'fuck,",
		"'fuck!'",
		'"fuck!"',
		"'fuck!'",
		"fucks",
		"fucker",
		'"fucker"',
		'"fucker',
		'"fucker,',
		"fucker,",
		",fucker",
		",fucker,",
		"fucker!",
		"'fucker'",
		"'fucker",
		"'fucker,",
		"'fucker!'",
		'"fucker!"',
		"'fucker!'",
		"fucked",
		'"fucked"',
		'"fucked',
		'"fucked,',
		"fucked,",
		",fucked",
		",fucked,",
		"fucked!",
		"'fucked'",
		"'fucked",
		"'fucked,",
		"'fucked!'",
		'"fucked!"',
		"'fucked!'",
		"fucking",
		'"fucking"',
		'"fucking',
		'"fucking,',
		"fucking,",
		",fucking",
		",fucking,",
		"fucking!",
		"'fucking'",
		"'fucking",
		"'fucking,",
		"'fucking!'",
		'"fucking!"',
		"'fucking!'",
		"motherfucker",
		'"motherfucker"',
		'"motherfucker',
		'"motherfucker,',
		"motherfucker,",
		",motherfucker",
		",motherfucker,",
		"motherfucker!",
		"'motherfucker'",
		"'motherfucker",
		"'motherfucker,",
		"'motherfucker!'",
		'"motherfucker!"',
		"'motherfucker!'",
		"shit",
		'"shit"',
		'"shit',
		'"shit,',
		"shit,",
		",shit",
		",shit,",
		"shit!",
		"'shit'",
		"'shit",
		"'shit,",
		"'shit!'",
		'"shit!"',
		"'shit!'",
		"ass",
		'"ass"',
		'"ass',
		'"ass,',
		"ass,",
		",ass",
		",ass,",
		"ass!",
		"'ass'",
		"'ass",
		"'ass,",
		"'ass!'",
		'"ass!"',
		"'ass!'",
		"bitch",
		'"bitch"',
		'"bitch',
		'"bitch,',
		"bitch,",
		",bitch",
		",bitch,",
		"bitch!",
		"'bitch'",
		"'bitch",
		"'bitch,",
		"'bitch!'",
		"'bitch!'",
		"bitches",
		"bastard",
		'"bastard"',
		'"bastard',
		'"bastard,',
		"bastard,",
		",bastard",
		",bastard,",
		"bastard!",
		"'bastard'",
		"'bastard",
		"'bastard,",
		"'bastard!'",
		'"bastard!"',
		"'bastard!'",
		"nigga",
		'"nigga"',
		'"nigga',
		'"nigga,',
		"nigga,",
		",nigga",
		",nigga,",
		"nigga!",
		"'nigga'",
		"'nigga",
		"'nigga,",
		"'nigga!'",
		"'nigga!'",
		"nigger",
		'"nigger"',
		'"nigger',
		'"nigger,',
		"nigger,",
		",nigger",
		",nigger,",
		"nigger!",
		"'nigger'",
		"'nigger",
		"'nigger,",
		"'nigger!'",
		'"nigger!"',
		"'nigger!'",
		"niggers",
		'"niggers"',
		'"niggers',
		'"niggers,',
		"niggers,",
		",niggers",
		",niggers,",
		"niggers!",
		"'niggers'",
		"'niggers",
		"'niggers,",
		"'niggers!'",
		"'niggers!'",
		"hoe",
		'"hoe"',
		'"hoe',
		'"hoe,',
		"hoe,",
		",hoe",
		",hoe,",
		"hoe!",
		"'hoe'",
		"'hoe",
		"'hoe,",
		"'hoe!'",
		'"hoe!"',
		"'hoe!'",
		"whore",
		'"whore"',
		'"whore',
		'"whore,',
		"whore,",
		",whore",
		",whore,",
		"whore!",
		"'whore'",
		"'whore",
		"'whore,",
		"'whore!'",
		'"whore!"',
		"'whore!'",
	  ],
	  is_banned_words: 0,
	  options: {option15: false, option30: false, optionWord15: false, optionWord30: false, is_anonymous: false},
	  payable: 0,
	  word_payable: 0,
	  anonymous_payable: 0,
	  words: 15,
	  total_words: 0,
	  time_limit: 15,
	  isOpen: false
    };
	
  }
 
  createWrapper = () => {
    this.setState({
      isCreate: !this.state.isCreate,
    });
  };

  categoryDropBox = (category) => {
    this.setState({
      categorySelect: !this.state.categorySelect,
    });

    if (category) {
      this.setState({
        category,
      });
    }
  };

  onChange = (content, delta, source, editor) => {
	
	var value = editor.getText();
	var characters = value.split(' ');
	if(characters[characters.length-1].length > 30) {
		setToast("warning", "You cannot enter more than 30 characters in a word.");
		return false;
	}
	const {words,banned_words} = this.state;
	if(value.split(' ').length-1 >= words) {
	  if(words >= 45) {
		  setToast("warning", "You cannot enter more than "+`${words}`+" words.");
	  }else {
		setToast("warning", "You cannot enter more than "+`${words}`+" words. You can add more.");
	  }
    } else {
      this.setState((state) => ({
        ...state,
        ["title"]: editor.getHTML(),
      }));
    }
	this.setState({total_words: value.split(' ').length-1});
	
	var word = value.split(' ');
	var found = banned_words.find((element) => {
		return found = word.find((elem) => {
			if(element.toLowerCase() == elem.toLowerCase()){
				setToast("warning", "Please select appropiate word");
				return element;
			}
		})
	})
	
	if(found) {
		this.setState({is_banned_words: 1});
	}else{
		this.setState({is_banned_words: 0});
	}
  };

  updateCheckBox = (e) => {
	  const {value} = e.target;
	  let options = this.state.options;
	  if(e.target.checked){
		this.setState(prevState => ({options: {...prevState.options, is_anonymous: true}}));
		this.setState({anonymous_payable: 1.99});
	  }else{
		  this.setState(prevState => ({options: {...prevState.options, is_anonymous: false}}));
		  this.setState({anonymous_payable: 0});
	  }
  }

  updateRadio = (e) => {
	  const {value} = e.target;
	  let options = this.state.options;
	  
	  switch (value) {
		  case '+15 seconds':
			//this.setState(prevState => ({...prevState.options:{option15: true, option30: false}}));
			this.setState(prevState => ({options: {...prevState.options,option15: true, option30: false}}));
			this.setState({payable: 1.99});
			this.setState({time_limit: 30});
			break;
		  case '+30 Seconds':
			//this.setState({options:{option15: false, option30: true}});
			this.setState(prevState => ({options: {...prevState.options,option15: false, option30: true}}));
			this.setState({payable: 3.99});
			this.setState({time_limit: 45});
			break;
		  case '+15 characters':
			//this.setState({options:{optionWord15: true, optionWord30: false}});
			this.setState(prevState => ({options: {...prevState.options,optionWord15: true, optionWord30: false}}));
			this.setState({word_payable: 1.99});
			this.setState({words: 30});
			break;
		  case '+30 characters':
			//this.setState({options:{optionWord15: false, optionWord30: true}});
			this.setState(prevState => ({options: {...prevState.options,optionWord15: false, optionWord30: true}}));
			this.setState({word_payable: 3.99});
			this.setState({words: 45});
			break;
		  default:
			break;
	  }
  }
  
  handleReset(e){
    e.preventDefault();
    e.target.reset();
	this.setState({options:{option15: false, option30: false, optionWord15: false, optionWord30: false}});
	this.setState({payable: 0});
	this.setState({time_limit: 15});
	this.setState({word_payable: 0});
	this.setState({words: 15});
  }

  handleSubmit = (e) => {
    e.preventDefault();
	const {option15, option30, optionWord15, optionWord30, is_anonymous} = this.state.options;
	const timeCost = option30 ? 3.99 : option15 ? 1.99 : 0;
	const wordCost = optionWord30 ? 3.99 : optionWord15 ? 1.99 : 0;
	const anonymousCost = is_anonymous ? 1.99 : 0;
    const { title, category, words, total_words, is_banned_words } = this.state;
	const { currentUser } = this.props;
	const t = option30 ? 45 : option15 ? 30 : 15;
	
	if(is_banned_words === 1) {
		setToast("warning", "Please select appropiate word");
		return;
	}

    if (!category) {
      setToast("warning", "Please select category");
      return;
    }
	
	if (!title) {
      setToast("warning", "Please add your weerd");
      return;
    }
	
	if(wordCost === 0) {
		if(total_words > 15) {
			setToast("warning", "You cannot enter more than "+`${words}`+" words. You can add more.");
			return;
		}
	}
	
	if ((timeCost > 0 || wordCost > 0 || anonymousCost > 0) && (currentUser.plan !== 'premium')) {
	  if(total_words > 30 && optionWord15 === true) {
		  setToast("warning", "You cannot enter more than "+`${words}`+" words. You can add more.");
		  return;
	  }else {
		this.props.setModal("weerdPayPalModalOpen");
	  }
    }else {
		try {
			APIRequest.request({
				method: 'POST',
				url: `/weerd`,
				data: {
				  author: currentUser._id,
				  title: title,
				  category: category,
				  time: t,
				  is_anonymous: is_anonymous,
				  weerd: { title, category, author: currentUser._id }
				}
			}).then((result) => {
				if(!is_anonymous) {
					APIRequest.request({
						method: 'POST',
						url: `/send-notification`,
						data: {
						  author: currentUser._id,
						  weerdId: result.data._id
						}
					});
				}
				window.location.href = `${environment.base_url}/`;
				setToast('success', 'Weerd has been posted successfully.');
			});
		} catch (error) {
			setToast('warning', error.message);
			console.log(error.message);
		}
	}
  };
  
  render() {
    const { title, isCreate, categorySelect, categories, category, payable, options, word_payable, anonymous_payable } =
      this.state;
	//const {is_anonymous,option15} = this.state.options;
    const { weerdPremiumModalOpen, weerdPayPalModalOpen, currentUser } = this.props;
	
    return (
      <section>
        <div className="container-smart">
          <div className="row">
		  {currentUser.plan !== 'premium' ?
            <div className="col-lg-12 sm:text-center" onClick={() => this.props.setModal('premiumModalOpen')}>
              <img
                className="go-premium cursor"
                src="/assets/images/go-premium.png"
                alt="Premium"
                style={{ visibility: "visible" }}
              />
            </div>
		  : ''}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center create">
                <div className="text-center">
                  {!isCreate && (
                    <button
                      className="btn_bg b-0 cursor"
                      onClick={this.createWrapper}
                    >
                      <span>Create A Weerd!</span>
                    </button>
                  )}
                </div>
                {isCreate && (
                  <div
                    className="modal-content crudModal p-8 sm:p-4 position-relative"
                    style={{ overflow: "visible" }}
                  >
                    <div className="row">
                      <form className="w-100" onSubmit={this.handleSubmit}>
                        <div className="col-lg-12 text-center ">
                          <img
                            style={{ width: "48px" }}
                            className="action-sure-image"
                            src="/assets/images/ee-image.png"
                            alt=""
                          />{" "}
                          <h5 className="fw-bold">Create A Weerd</h5>
                        </div>
                        <div className="col-lg-12 text-center mt-16 sm:mt-8 custom-options">
                          <ReactQuill name="title" value={title} modules={Create.modules} formats={Create.formats} onChange={this.onChange} />
                          <div className="select">
                            <select className="select-hidden">
                              <option>Select Category</option>
                              {categories.map((category, index) => (
                                <option key={`hidden-category-${index}`}>
                                  {category}
                                </option>
                              ))}
                            </select>
                            <div
                              className="select-styled active"
                              onClick={() => this.categoryDropBox(category)}
                            >
                              {category || "Select Category"}
                            </div>
                            <ul
                              className="select-options"
                              style={{
                                display: categorySelect ? "block" : "none",
                              }}
                            >
                              <li rel="Select Category">Select Category</li>
                              {categories.map((category, index) => (
                                <li
                                  key={`li-category-${index}`}
                                  rel={category}
                                  onClick={() => this.categoryDropBox(category)}
                                >
                                  {category}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between mt-12 sm:mt-8">
                          <button
                            type="button"
                            onClick={this.createWrapper}
                            className="button red radius border-black fs-poppins"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="button green radius border-black fs-poppins"
                          >
                            Create
                          </button>
                        </div>
						<div>Word Limit: {this.state.words}</div>
						<div>Time Limit: {this.state.time_limit} seconds</div>
                      </form>
                    </div>
                  </div>
                )}
				<label htmlFor="anonymous">
				<input
				  type="checkbox"
				  name="anonymous"
				  id="anonymous"
				  value="anonymous"
				  onClick={this.updateCheckBox}
				/>
                <img
                  className="mt-16 cursor block"
                  id="create"
                  src="/assets/images/ee-image.png"
                  alt="Weerd create image"
                />
				</label>
				<p>Post Weerd Anonymously</p>
				{currentUser.plan !== 'premium' ? <span className="d-block">$1.99</span> : ""}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto m-auto">
              <div className=" circle mt-8 ">
			  <form className="w-100" onSubmit={this.handleReset.bind(this)}>
                <ul className="cursor">
                  <li
                    className="playSound"
                    // onClick={() => this.props.setModal("weerdPremiumModalOpen")}
                  >
				  <label htmlFor="15-seconds">
					<input
                      type="radio"
                      name="purchaseable_plan"
                      id="15-seconds"
                      value="+15 seconds"
					  onClick={this.updateRadio}
                    />
                    
                    <img
                      className="hvr-up"
                      src="/assets/images/15-a.svg"
                      alt="icon"
                    />
				  </label>
                    <p>
                      15 more seconds for Weerd display{" "}
                      {currentUser.plan !== 'premium' ? <span className="d-block"> $1.99</span> : ""}
                    </p>
                  </li>
                  <li
                    className="playSound"
                    // onClick={() => this.props.setModal("weerdPremiumModalOpen")}
                  >
				  <label htmlFor="30-seconds">
					<input
                      type="radio"
                      name="purchaseable_plan"
                      id="30-seconds"
                      value="+30 Seconds"
					  onClick={this.updateRadio}
                    />
					
                    <img
                      className="hvr-up"
                      src="/assets/images/30-a.svg"
                      alt="icon"
                    />
				  </label>
                    <p>
                      30 more seconds for Weerd display{" "}
                      {currentUser.plan !== 'premium' ? <span className="d-block">$3.99</span> : ""}
                    </p>
                  </li>
                  <li
                    className="playSound"
                    // onClick={() => this.props.setModal("weerdPremiumModalOpen")}
                  >
				  <label htmlFor="15-characters">
					<input
                      type="radio"
                      name="purchaseable_plan2"
                      id="15-characters"
                      value="+15 characters"
					  onClick={this.updateRadio}
                    />
					
                    <img
                      className="hvr-up"
                      src="/assets/images/15-b.svg"
                      alt="icon"
                    />
				  </label>
                    <p>
                      15 more words for Weerds{" "}
                      {currentUser.plan !== 'premium' ? <span className="d-block">$1.99</span> : ""}
                    </p>
                  </li>
                  <li
                    className="playSound"
                    // onClick={() => this.props.setModal("weerdPremiumModalOpen")}
                  >
				  <label htmlFor="30-characters">
					<input
                      type="radio"
                      name="purchaseable_plan2"
                      id="30-characters"
                      value="+30 characters"
					  onClick={this.updateRadio}
                    />
					
                    <img
                      className="hvr-up"
                      src="/assets/images/30-b.svg"
                      alt="icon"
                    />
				  </label>
                    <p>
                      30 more words for Weerds{" "}
                      {currentUser.plan !== 'premium' ? <span className="d-block">$3.99</span> : ""}
                    </p>
                  </li>
				  <input type="submit" className="btn btn-primary" value="reset" />
                </ul>
				
				</form>
              </div>
            </div>
          </div>
        </div>
        
		
		{weerdPayPalModalOpen && (
        <WeerdPayPalModal
		  open={() => this.props.setModal("weerdPayPalModalOpen")}
		  isOpen={weerdPayPalModalOpen}
          customStyles={customStyles}
          amount={payable+word_payable+anonymous_payable}
          time={options.option30 ? 45 : options.option15 ? 30 : 15}
		  weerd={{ title, category, author: currentUser._id, anonymous_payable }}
        />
      )}
      </section>
    );
  }
}

Create.modules = {
  toolbar: [
    [{ 'font': Font.whitelist }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['clean']
  ]
}

Create.formats = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    currentUser: sm.user,
    weerdPremiumModalOpen: sm.weerdPremiumModalOpen,
	weerdPayPalModalOpen: sm.weerdPayPalModalOpen,
  };
};

const mapActionsToProps = {
  setModal: setWeerdyModal,
};

export default connect(mapStateToProps, mapActionsToProps)(Create);
