import { register, authenticate, putUser, deactivateUser } from "./apiCalls";
import {
  registerUser,
  signInUser,
  signOutUser,
  editUser,
  deleteUser,
  setLoadingStatus,
  setErrorMessage,
} from "./actions";
import { setToken, removeToken, getToken } from "../../lib/apiRequest";
import { setToast } from "../../lib/toast";
import environment from "../../environments/environment.dev";

const setStatus = (status, message) => (dispatch) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const signUp = (candidate) => async (dispatch) => {
  dispatch(setStatus(true, ""));

  try {
    const { data: user } = await register(candidate);
	if(user.error) {
		setToast("error", user.error);
	}
    else if (user) {
      dispatch(registerUser(user));
      setToast("success", "You have successfully registered!");
    }

    dispatch(setStatus(false, ""));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const signIn = (credentials) => async (dispatch) => {
  dispatch(setStatus(true, ""));

  try {
    const { data: user } = await authenticate(credentials);

    if (user) {
      setToken("user", user);
      dispatch(signInUser(user));
      setToast("success", "Your account has been logged in successfully!");
    }

    dispatch(setStatus(false, ""));
    window.location.href = `${environment.base_url}/`;
  } catch (error) {
    setToast("error", "Invalid credentials or unverified account!");
    dispatch(setStatus(false, error.message));
  }
};

export const userEdit = (user) => async (dispatch) => {
  dispatch(setStatus(true, ""));
  try {
    await putUser(user);
    console.log("runs");

    const { password, ...candidate } = user;
    const currentUser = getToken("user");

    const update = {
      ...currentUser,
      ...candidate,
    };

    setToken("user", update);

    if (update) {
      dispatch(editUser(update));
      setToast("success", "Your account has been updated successfully!");
    }

    dispatch(setStatus(false, ""));
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};

export const signOut = () => async (dispatch) => {
  removeToken("user");
  dispatch(signOutUser());
  dispatch(setStatus(false, ""));
  window.location.href = `${environment.base_url}/`;
};

export const userRemove = (user) => async (dispatch) => {
  dispatch(setStatus(true, ""));

  try {
    await deactivateUser(user);
    removeToken("user");
    dispatch(deleteUser());
    dispatch(setStatus(false, ""));
    setToast("error", "Your account has been deactivated!");
    window.location.href = `${environment.base_url}/`;
  } catch (error) {
    dispatch(setStatus(false, error.message));
  }
};
