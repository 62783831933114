import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const CreateConfirmModal = ({
  open,
  isOpen,
  customStyles,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={(e) => open(e, "createConfirmModalOpen")}
      closeTimeoutMS={500}
      style={customStyles}
      className="modal fade show"
    >
      <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div className="modal-content crudModal p-8 sm:p-4 position-relative">
          <div className="row">
            <div className="col-lg-12 text-left p-0">
              <img className="action-confirm-image1" src="/assets/images/ee-image.png" alt="confirm image 1" />
            </div>
            <div className="col-lg-12 text-center">
              <img className="action-confirm-image2" src="/assets/images/tick.svg" alt="confirm image 2" />
              <h5 className="fw-ebold mt-8 set_purchaseAble_success_text">+15 Second Purchased</h5>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CreateConfirmModal;
