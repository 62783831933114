import React, { useEffect, useState } from "react";
import { useRef } from "react";
import environment from '../../environments/environment.dev';
import { addMessage, getMessages, updateMessageRead } from "../../api/MessageRequests";
import { getUser } from "../../api/UserRequests";
import "./ChatBox.css";
import { format } from "timeago.js";
//import InputEmoji from 'react-input-emoji'

const ChatBox = ({ chat, currentUser, setSendMessage,  receivedMessage, online }) => {
  const [userData, setUserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleChange = (newMessage)=> {
    setNewMessage(newMessage.target.value)
  }

   const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      var btn = document.getElementById('send_button');
      btn.click();
      //console.log("CLICKED")
    }
  };


  // fetching data for header
  useEffect(() => {
    const userId = chat?.members?.find((id) => id !== currentUser);
    const getUserData = async () => {
      try {
        const { data } = await getUser(userId);
        setUserData(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (chat !== null) getUserData();
  }, [chat, currentUser]);

  // fetch messages
  useEffect(() => {
    const userId = chat?.members?.find((id) => id !== currentUser);
    const fetchMessages = async () => {
      try {
        const { data } = await getMessages(chat._id);
        setMessages(data);
        
        const el = document.getElementById(userId);
        var count = el.innerText;

        //Need to decrease the header chat message total count
        el.innerText = 0;
        el.style.display = "none";          

      } catch (error) {
        console.log(error);
      }
    };

    if (chat !== null) fetchMessages();
  }, [chat, currentUser]);


  // Always scroll to last Message
  useEffect(()=> {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  },[messages])



  // Send Message
  const handleSend = async(e)=> {

    console.log(newMessage);

    e.preventDefault()
    const message = {
      senderId : currentUser,
      text: newMessage,
      chatId: chat._id,
      isRead: false,
  }
  const receiverId = chat.members.find((id)=>id!==currentUser);
  // send message to socket server
  setSendMessage({...message, receiverId})
  // send message to database
  try {
    const { data } = await addMessage(message);
    setMessages([...messages, data]);
    setNewMessage("");
  }
  catch
  {
    console.log("error")
  }
}


// Receive Message from parent component
useEffect(()=> {
  console.log("Message Arrived: ", receivedMessage);

  try {

    const el = document.getElementById(receivedMessage.senderId);
    var count = el.innerText;
    count = parseInt(count)+1;
    el.innerText = count;
    el.style.display = "inline";

    if (receivedMessage !== null && receivedMessage.chatId === chat._id) {
        el.innerText = 0;
        el.style.display = "none"; 
        setMessages([...messages, receivedMessage]);

        updateMessageRead(chat._id);
    }
  }
  catch
  {
    console.log("error")
  } 

},[receivedMessage])



  const scroll = useRef();
  const imageRef = useRef();
    return (
    <>
        <div className="ChatBox-container">
            {chat ? (
            <>
            {/* chat-header */}
            <div className="chat-header">
                <div className="img-sec">
                    <img src={userData?.profile_img? environment.img_api+"public/userImages/"+ userData.profile_img : environment.img_api+"public/images/defaultProfile.png"} className="followerImage" alt={userData?.username} />
                    <div className="status-dot" style={{"background-color": online?"#51e200":""}}></div>
                </div>
                <div className="name">
                    <span>{userData?.username}</span>
                </div>
            </div>
            {/* chat-body */}
            <div className="chat-body" >
              {messages.map((message) => (
                <>
                  <div ref={scroll}
                    className={
                      message.senderId === currentUser
                        ? "msg-box send"
                        : "msg-box"
                    }
                  >
  
                    <div className="msg-details">
                        <div className="bg-color">
                            <span>{message.text}</span>
                        </div>
                        <span className="msg-time">{format(message.createdAt)}</span>
                    </div>
                  </div>
                </>
              ))}
            </div>
            {/* chat-sender */}
            <div className="chat-sender">
     
              <textarea 
        rows="2"
        value={newMessage}
        onChange={handleChange}
        onKeyDown={onKeyDownHandler}
      />
              
              <div className="send-button button" id="send_button" onClick = {handleSend}>Send</div>
              <input
                type="file"
                name=""
                id=""
                style={{ display: "none" }}
                ref={imageRef}
              />
            </div>{" "}
          </>
        ) : (
          <span className="chatbox-empty-message" style={{"padding-top":"45px;"}}>
           
            Tap on a chat to start conversation...
          </span>
        )}
      </div>
    </>
  );
};

export default ChatBox;
