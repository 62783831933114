import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Countdown from "react-countdown";
import Modal from 'react-bootstrap/Modal';

import { stateMappings } from "../redux/stateMappings";
import { weerdGets, weerdEdit } from "../redux/weerd/thunks";
import { setToast } from "../lib/toast";
import { socket } from '../socket';

const TIMELIMIT = 15000;

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeWeerd: this.props.weerds.length - 1,
      start: Date.now() + TIMELIMIT,
      categoryActive: false,
	  counter: 0,
	  ads: [
        {"pic": "1.svg", "url": "https://vidmondo.com/"},
        {"pic": "2.svg", "url": "https://redcircle.com/shows/the-really-hood-podcast"},
        {"pic": "3.svg", "url": "https://reallyhood.com/"},
        {"pic": "4.svg", "url": "https://brokenheartshirts.com/"},
        {"pic": "5.svg", "url": "https://lovacane.com/"},
        {"pic": "6.svg", "url": "https://ritchieknoxart.com/"}
      ],
      adActive: false,
      activeAd: 0,
	  show: false,
	  weerdtext: "",
	  online: 1
    };

    this.categoriesRef = React.createRef();
  }

  componentDidMount() {
    this.props.gets();
	socket.on('online_users', function(data) {
	  console.log(data);
	  this.setState({online: data});
	}.bind(this));
  }

  componentDidUpdate(prevProps, prevStates) {
    // Typical usage (don't forget to compare props):
    if (this.props.weerds.length !== prevProps.weerds.length) {
      this.setActiveWeerd(this.props.weerds.length - 1, this.state.counter+1);
    }
  }

  handleClose = () => {
	  this.setState({show: false});
  }

  handleShow = (title) => {
	  const { weerds, currentUser } = this.props;
	  const weerd = weerds[this.state.activeWeerd];
	  if (weerd.author._id === currentUser._id) {
		  setToast("info", "This is your Weerd");
		  return;
	  }
	  this.setState({show: true});
	  this.setState({weerdtext: title});
  }

  setActiveWeerd = (index, counter) => {
    const { weerds } = this.props;
    this.setState(
      {
        activeWeerd: index,
		adActive: false,
        start: Date.now() + weerds[index].time * 1000,
		counter: counter
      },
      () => {
        this.actions("view", false);
      }
    );
  };

  setActiveAd = (index, counter) => {
    this.setState({
      activeAd: index,
      adActive: true,
      start: Date.now() + TIMELIMIT,
      counter: counter
    }, () => {
      this.actions('view', false);
    });
  }

  actions = (type, isToast = true) => {
    const { weerds, edit, currentUser, isAuthenticated } = this.props;
    const weerd = weerds[this.state.activeWeerd];
	
	socket.emit('join_room');
	
    if (!isAuthenticated) {
      if (isToast) {
        setToast("warning", "You should login first to do this action.");
      }
      return;
    }
	
	if((weerd['likes']).includes(currentUser._id)) {
		if(type === 'dislike') {
			return;
		}
		
		if(type === 'right') {
			return;
		}
	}
	if((weerd['dislikes']).includes(currentUser._id)) {
		if(type === 'like') {
			return;
		}
		
		if(type === 'share') {
			return;
		}
	}
	if((weerd['rights']).includes(currentUser._id)) {
		if(type === 'like') {
			return;
		}
		
		if(type === 'share') {
			return;
		}
	}
	if((weerd['shares']).includes(currentUser._id)) {
		if(type === 'dislike') {
			return;
		}
		
		if(type === 'right') {
			return;
		}
	}
	 try {
    if (weerd.author._id === currentUser._id) {
      setToast("info", "This is your Weerd");
      return;
    }
   } catch (error) {
    
   }


    if ((weerd[`${type}s`] || []).includes(currentUser._id)) {
      /*if (type !== "view") {
        setToast('info', `You already selected ${type}`);
      }*/
      return;
    }
	
    edit(
      {
        ...weerd,
        [`${type}s`]: [...weerd[`${type}s`], currentUser._id],
        [type]: currentUser._id,
        type,
      },
      false
    );
  };

  timeRenderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      if((this.state.counter)%5 != 0) {
        const index = this.state.activeWeerd - 1;
        this.setActiveWeerd(index < 0 ? this.props.weerds.length - 1 : index, this.state.counter + 1);
      }
      else {
        //if(this.state.counter == 0 || (this.props.isAuthenticated && this.props.currentUser.plan != "free")) {
		if(this.state.counter == 0) {
          const index = this.state.activeWeerd - 1;
          this.setActiveWeerd(index < 0 ? this.props.weerds.length - 1 : index, this.state.counter + 1);
        }
        else {
          const index = this.state.activeAd - 1;
          this.setActiveAd(index < 0 ? this.state.ads.length - 1 : index, 0);
        }
      }
    }

    return <Fragment>{`00:${seconds < 10 ? "0" : ""}${seconds}`}</Fragment>;
  };

  activeCategoryDropBox = () => {
    this.setState({
      categoryActive: !this.state.categoryActive,
    });
  };

  categoryScroll = () => {
    this.categoriesRef &&
      this.categoriesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    const { weerds, loading, isAuthenticated } = this.props;
    const { activeWeerd, start, categoryActive, ads, adActive, activeAd, show, weerdtext, online } = this.state;

    return (
      <section>
        {!loading && (
          <div className="container">
            <div className="row ">
              <div className="col-lg-2 col-md-4 col-sm-12">
                <button
                  className="cat-btn button w-100 radius border-black"
                  onClick={this.activeCategoryDropBox}
                >
                  Categories
                </button>
                <div className={`categories${categoryActive ? " active" : ""}`}>
                  <ul ref={this.categoriesRef}>
                    <li>
                      <a href="/?category=General">General</a>
                    </li>
                    <li>
                      <a href="/?category=Politics">Politics</a>
                    </li>
                    <li>
                      <a href="/?category=Sports">Sports</a>
                    </li>
                    <li>
                      <a href="/?category=Technology">Technology</a>
                    </li>
                    <li>
                      <a href="/?category=Fashion">Fashion</a>
                    </li>
                    <li>
                      <a href="/?category=Gaming">Gaming</a>
                    </li>
                    <li>
                      <a href="/?category=Food">Food</a>
                    </li>
                    <li>
                      <a href="/?category=Travel">Travel</a>
                    </li>
                    <li>
                      <a href="/?category=Art">Art</a>
                    </li>
                    <li>
                      <a href="/?category=Education">Education</a>
                    </li>
                    <li>
                      <a href="/?category=Science">Science</a>
                    </li>
                    <li>
                      <a href="/?category=Comedy">Comedy</a>
                    </li>
                    <li>
                      <a href="/?category=Entertainment">Entertainment</a>
                    </li>
                    <li>
                      <a href="/?category=Movies">Movies</a>
                    </li>
                    <li>
                      <a href="/?category=Gossip">Gossip</a>
                    </li>
                    <li>
                      <a href="/?category=Finance">Finance</a>
                    </li>
                  </ul>
                  <button id="scroll" onClick={this.categoryScroll}>
                    <img src="/assets/images/arrow-bottom.png" alt="" />
                  </button>
                </div>
              </div>
              {activeWeerd !== -1 && weerds.length > 0 && !adActive && (
                <Fragment>
                  <div className="col-lg-8 col-md-8 col-sm-12 sm:mt-8">
                    <div className="border-box text-center border-black radius-xl">
                      {(weerds[activeWeerd].author || {}).plan ===
                        "premium" && (
                        <img
                          className="badge_img"
                          src="/assets/images/badge.svg"
                          alt="badge"
                        />
                      )}
                      <p className="fw-bold border-bottom-black color-twitter py-2">{!weerds[activeWeerd].is_anonymous ? <a href={"user-profile/?user="+(weerds[activeWeerd].author || {})._id}>@{
                        (weerds[activeWeerd].author || {}).username
                      }</a> : 'Anonymous'}</p>
                      <div className="fw-ebold focus-editing" name="main-weerd" dangerouslySetInnerHTML={{__html: weerds[activeWeerd].title}} style={{height: "150px"}} readOnly></div>
                    </div>
                    <div className="reactions mt-16 mb-4 sm:my-8">
                      <ul>
                        <li onClick={() => this.actions("like")}>
                          <img
                            className="hvr-up"
                            src="/assets/images/thumbs-up.png"
                            alt="Like"
                          />{" "}
                          <p className="likes">
                            {(weerds[activeWeerd].likes || []).length}
                          </p>
                        </li>
                        <li onClick={() => this.actions("dislike")}>
                          <img
                            className="hvr-down"
                            src="/assets/images/thumbs-down.png"
                            alt="Dislike"
							disabled={true}
                          />{" "}
                          <p className="dislikes">
                            {(weerds[activeWeerd].dislikes || []).length}
                          </p>
                        </li>
                        <li
                          onClick={() => this.actions("right")}
                          className="position-relative"
                        >
                          <img
                            className="hvr-right"
                            src="/assets/images/alarm.png"
                            alt="Report"
                          />
                          {/* <i className="fa fa-info-circle" aria-hidden="true" data-toggle="popover" data-placement="top" data-content="Please use the whistle to report anything that is lewd, offensive, dangerous, or incities voilence."></i> */}
                          <p className="reports">
                            {(weerds[activeWeerd].rights || []).length}
                          </p>
                        </li>
                        <li onClick={() => this.actions("share")}>
                          <img onClick={() => isAuthenticated ? this.handleShow(weerds[activeWeerd]._id) : ""}
                            className="hvr-up"
                            src="/assets/images/telegram.png"
                            alt="Share"
                          />
                          <p className="share">
                            {(weerds[activeWeerd].shares || []).length}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-16">
                      <p className="color-blue fw-ebold text-center h2">
                        {/* 00:<span id="time">15</span> */}
                        <Countdown
                          key={start}
                          date={start}
                          renderer={this.timeRenderer}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2"></div>
                </Fragment>
              )}
            </div>
            {activeWeerd === -1 && !adActive && (
              <div className="row justify-content-center mt-4">
                <strong>There are no weerds yet for this category.</strong>
              </div>
            )}
            {activeWeerd !== -1 && !adActive && (
              <div className="container-smart pb-16 md:py-12">
                <div className="row">
                  <p className="total-reactions ">
					<span className="fs-poppins fw-bold" id="result">
                      
					  {online}
                    </span>{" "}
                    <img
                      className="v-middle"
                      src="/assets/images/visible.png"
                      alt="Eye-icon"
                    />
                  </p>
                </div>
              </div>
            )}
			{adActive &&
              <Fragment>
                <div className="offset-lg-2 col-lg-8 offset-md-2 col-md-8 col-sm-12 sm:mt-8">
                  <div className="adwrapper" style={{display: "block", marginLeft: "auto", marginRight: "auto", width: "50%"}}>
                    <a href={ads[activeAd].url} target='_blank'>
                      <img className="ad-pic" src={process.env.PUBLIC_URL + `/ads/${ads[activeAd].pic}`} />
                    </a>
                  </div>
                  
                  <div className="mt-16">
                    <p className="color-blue fw-ebold text-center h2">
                      <Countdown
                        key={start}
                        date={start}
                        renderer={this.timeRenderer}
                      />
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2">
                </div>
              </Fragment>
            }
          </div>
        )}
		<Modal show={show} onHide={this.handleClose}>
			<Modal.Header closeButton>
			  <Modal.Title>Share</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<a href={"https://www.facebook.com/sharer/sharer.php?u=https://weerdy.com/weerd?text="+weerdtext} target="_blank"><img src="./assets/images/facebook.png" /></a>
			<a href={"https://twitter.com/intent/tweet?url=https://weerdy.com/weerd?text="+weerdtext} target="_blank"><img src="./assets/images/twitter.png" /></a>
			<a href={"https://api.whatsapp.com/send?text=https://weerdy.com/weerd?text="+weerdtext} target="_blank"><img src="./assets/images/whatsapp.png" /></a>
			</Modal.Body>
		</Modal>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const sm = stateMappings(state);
  // const query = new URLSearchParams(ownProps.location.search);
  const categoryName =
    // query.get('category');
    decodeURIComponent(
      window.location.search.replace(
        new RegExp(
          "^(?:.*[&\\?]" +
            encodeURIComponent("category").replace(/[\.\+\*]/g, "\\$&") +
            "(?:\\=([^&]*))?)?.*$",
          "i"
        ),
        "$1"
      )
    );
  return {
    isAuthenticated: sm.isAuthenticated,
    weerds: (sm.weerds || []).filter(
      ({ category }) => !categoryName || category === categoryName
    ),
    currentUser: sm.user || {},
    loading: sm.loading,
	counter: sm.counter,
    ads: sm.ads,
    adActive: sm.adActive,
    activeAd: sm.activeAd
  };
};

const mapActionsToProps = {
  gets: weerdGets,
  edit: weerdEdit,
};

export default connect(mapStateToProps, mapActionsToProps)(Landing);
