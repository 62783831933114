import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';

import { stateMappings } from '../redux/stateMappings';
import { signIn } from '../redux/auth/thunks';

class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <section className="py-24 sm:py-12">
        <div className="container-smart">
          <div className="row row-eq-height">
            <div className="col-lg-12 col-md-12 col-sm-12 sm:mt-8">
              <div className="box-card weerdy-detail">
                <h3 className="fw-ebold mb-8 text-center color-green">Weerdy Categories</h3>
                <ul className="cats-list">
                  <li className="hvr-right"><a href="/">General</a></li>
                    <li className="hvr-right"><a href="/?category=?Politics">Politics</a></li>
                    <li className="hvr-right"><a href="/?category=?Sports">Sports</a></li>
                    <li className="hvr-right"><a href="/?category=?Technology">Technology</a></li>
                    <li className="hvr-right"><a href="/?category=?Fashion">Fashion</a></li>
                    <li className="hvr-right"><a href="/?category=?Gaming">Gaming</a></li>
                    <li className="hvr-right"><a href="/?category=?Food">Food</a></li>
                    <li className="hvr-right"><a href="/?category=?Travel">Travel</a></li>
                    <li className="hvr-right"><a href="/?category=?Art">Art</a></li>
                    <li className="hvr-right"><a href="/?category=?Education">Education</a></li>
                    <li className="hvr-right"><a href="/?category=?Science">Science</a></li>
                    <li className="hvr-right"><a href="/?category=?Comedy">Comedy</a></li>
                    <li className="hvr-right"><a href="/?category=?Entertainment">Entertainment</a></li>
                    <li className="hvr-right"><a href="/?category=?Movies">Movies</a></li>
                    <li className="hvr-right"><a href="/?category=?Gossip">Gossip</a></li>
                    <li className="hvr-right"><a href="/?category=?Finance">Finance</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  signIn
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Categories);

