import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { stateMappings } from '../redux/stateMappings';
import { signOut } from '../redux/auth/thunks';
import { setWeerdyModal } from '../redux/modal/thunks';
import { APIRequest } from '../lib/apiRequest';
import { setToast } from '../lib/toast';
import environment from '../environments/environment.dev';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
		imgSrc: "",
		imgCollection: "",
		profile_pic: "",
    };
  }

  componentDidMount() {
		const {user} = this.props;
		
		APIRequest.request({
			method: 'GET',
			url: `/user/${user._id}`
		 }).then(result => {
			 this.setState({profile_pic: result.data.profile_img});
		 });
	}

  fileSelectedHandler = event => {
	var reader = "";
	
	reader = new FileReader();
	reader.readAsDataURL(event.target.files[0]);
	reader.onload = (e)=> {
		this.setState({imgSrc: e.target.result});
	}
  }
  
  upload = event => {
	const { imgSrc }  = this.state;
	const {user} = this.props;
	APIRequest.request({
		method: 'PUT',
		url: `/update-images`,
		data: {
		  imgCollection: imgSrc,
		  user: user._id
		}
	 }).then(result => {
		  setToast('success', 'Image is uploaded successfully.');
		  window.location.reload(false);
	 });
  }

  render() {
    const { signOut, setWeerdyModal, user } = this.props;
    const { username, email, plan } = this.props.user || {};
	const {profile_pic} = this.state || "";

    return (
      <SectionContainer className="py-24 sm:py-12">
        <div className="container-smart">
          <div className="row row-eq-height">
            <div className="col-lg-4 col-md-4 col-sm-12 column">
              <div className="box-card text-center relative">
                <div className="v-center detail">

				{plan === "premium" ?
        <>
				  <label htmlFor="file-input" style={{cursor: "pointer"}}>
					<i className="fas fa-camera"></i>
					<input id="file-input" type="file" accept="image/*" style={{display: "none"}} onChange={this.fileSelectedHandler} /> 
					</label>

				{profile_pic ? <img className="ee-image" src={environment.img_api+"public/userImages/"+profile_pic} alt="profile-ee" /> : <img className="ee-image" src={"assets/images/ee-image.png"} alt="profile-ee" /> }
				  {this.state.imgSrc ? <img src={this.state.imgSrc} style={{height: "20%", width: "20%"}} /> : ""}
				  {this.state.imgSrc ? <a href="#" onClick={this.upload} className="btn btn-primary">Upload</a> : ""}

        </>
					: <img className="ee-image" src={"assets/images/ee-image.png"} alt="profile-ee" />
				}

                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 column sm:mt-8">
              <div className="box-card weerdy-detail">
                  <h2 className="fw-ebold mb-4 text-center">Profile</h2>
                  <div className="p-3">
                    <ul className="setting">
                      <li>
                        <h5 className="fw-ebold color-green">User Name</h5>
                        <p className="m-0 ">{ username }</p>
                      </li>
                      <li>
                        <h5 className="fw-ebold color-green">Email Address</h5>
                        <p className="m-0 ">{ email }</p>
                      </li>
                      <li>
                        <h5 className="fw-ebold color-green">Current Plan</h5>
                        <p className="m-0 plan">{plan || "Free"} {user.plan !== 'premium' ? <span className="float-right color-red fw-bold" onClick={() => setWeerdyModal('premiumModalOpen')}>Go Premium</span> : ""}</p>
                      </li>
                      <li>
                        <button className="button red radius border-black" onClick={() => signOut()}>Log Out</button>
                      </li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </SectionContainer>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
  };
};

const mapActionsToProps = {
  signOut,
  setWeerdyModal,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(Profile);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`


