/* provides a mapping to each nested (or not) endPoints so we can change
   the Redux store without having to change every connected component */
// import environment from '../environments/environment.dev';

export const stateMappings = ({ _newReduxTree }) => {
  const { auth, admin, modal, weerd } = _newReduxTree;
  console.log(auth);
  return {
    ...modal,
    isAuthenticated: auth.user?.token,
    //  !!auth.user.token,
    isAdmin: auth.user.role === "admin",
    user: auth.user,
    users: admin.users,
    weerds: weerd.weerds,
    loading: auth.loading || weerd.loading,
  };
};
