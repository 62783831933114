import {
  SIGNIN_USER,
  SIGNOUT_USER,
  REGISTER_USER,
  EDIT_USER,
  DELETE_USER,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
} from "./actionEnum";
import { getToken } from "../../lib/apiRequest";

const defaultState = {
  loading: false,
  error: "",
  user: getToken("user"),
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return { ...state, user: action.payload };

    case SIGNIN_USER:
      return { ...state, user: action.payload };

    case EDIT_USER:
      return { ...state, user: action.payload };

    case SIGNOUT_USER:
      return { ...state, user: {} };

    case DELETE_USER:
      return { ...state, user: {} };

    case SET_LOADING_STATUS:
      return { ...state, loading: action.payload };

    case SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};

export default reducer;
