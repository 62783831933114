export const SET_VIDEOS = "SET_VIDEOS";
export const SET_ALL_VIDEOS = "SET_ALL_VIDEOS";
export const SET_SHARED_VIDEOS = "SET_SHARED_VIDEOS";
export const SET_ALL_COMMENTS ="SET_ALL_COMMENTS";
export const ADD_VIDEO = "ADD_VIDEO";
export const ADD_COMMENT = "ADD_COMMENT";
export const EDIT_VIDEO = "EDIT_VIDEO";
export const REMOVE_VIDEO = "REMOVE_VIDEO";
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
