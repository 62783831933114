import axios from "axios";

import environment from '../environments/environment.dev';


export const getToken = (key, defaultValue) => {
  const token = localStorage.getItem(key);
  return token ? JSON.parse(token) : (defaultValue ? defaultValue : {});
};

export const setToken = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeToken = (key) => {
  localStorage.removeItem(key);
}

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
  }
};

export let APIRequest = axios.create({
  baseURL: environment.api_url,
  headers: getHeaders()
});

export const refreshInstance = () =>
  (APIRequest = axios.create({
    baseURL: environment.api_url,
    headers: getHeaders()
  }));
