import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTotalUnreadMessages } from "../api/MessageRequests";

const Badge = ({currentUser}) => {
  
  const [userUnreadMessageData, setTotalUnreadMessageData] = useState([]);
  const dispatch = useDispatch()

  useEffect(()=> {

    const getTotalMessageUnreadData = async ()=> {
      try
      {
          const {data} =await getTotalUnreadMessages(currentUser)
          setTotalUnreadMessageData(data)
      }
      catch(error)
      {
        console.log(error)
      }
    }
  
    getTotalMessageUnreadData();
  }, [])

  return (
    <>
      <li className="hover-blue position-relative">
        <a href="/chat"><i className="fa fa-comment"></i><span id="total_unread_msg_cnt">{userUnreadMessageData.length}</span></a>
      </li>
    </>
  );
};

export default Badge;
