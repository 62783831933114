import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";

import { stateMappings } from "../redux/stateMappings";
import { signUp } from "../redux/auth/thunks";
import { setWeerdyModal } from "../redux/modal/thunks";
import { setToast } from "../lib/toast";

Modal.setAppElement("#root");

class RegisterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      password_confirm: "",
      role: "user",
      passwordHidden: true,
    };
  }

  onChange = ({ target: { name, value } }) =>
    this.setState((state) => ({
      ...state,
      [name]: value,
    }));

  handleSubmit = async (e) => {
    e.preventDefault();

    const { password_confirm, passwordHidden, email, ...candidate } =
      this.state;
    const { signUp, setModal } = this.props;
	
	let digits = '0123456789';
	let otp = '';
	
	for(let i = 0; i< 17; i++) {
		otp += digits[Math.floor(Math.random() * 10)];
	}
	
    if (password_confirm !== this.state.password) {
      setToast("error", "Re-type password doesn't match Password");
      return;
    }

    try {
      await signUp({
        ...candidate,
        email: email.toLowerCase(),
        plan: "free",
		verification_token: otp
      });
    } catch (error) {
      return;
    }

    setModal("signInModalOpen");
  };

  setPasswordVisibility = () => {
    this.setState({
      passwordHidden: !this.state.passwordHidden,
    });
  };

  render() {
    const { open, isOpen, customStyles, isAuthenticated } = this.props;
    const { username, email, password, password_confirm, passwordHidden } =
      this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={(e) => open(e, "signUpModalOpen")}
        closeTimeoutMS={500}
        style={customStyles}
        className="modal fade show"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content onboardingModal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 my-auto text-center">
                <img
                  className="ee-image"
                  src="/assets/images/ee-image.png"
                  alt="register-ee"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form className="signup-form" onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label className="input-label" htmlFor="name">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control radius border-black fs-poppins"
                      name="username"
                      value={username}
                      onChange={this.onChange}
                      required
                    />
                    <span className="form-control-feedback fa fa-check"></span>
                  </div>
                  <div className="form-group">
                    <label className="input-label" htmlFor="name">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control radius border-black fs-poppins"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      required
                    />
                    <span className="form-control-feedback fa fa-check"></span>
                  </div>
                  <div className="form-group">
                    <label className="input-label" htmlFor="password">
                      Password
                    </label>
                    <input
                      type={passwordHidden ? "password" : "text"}
                      className="form-control radius border-black fs-poppins"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                      required
                    />
                    <img
                      className="toggle-password"
                      src={`/assets/images/${
                        passwordHidden ? "visible" : "hidden"
                      }.png`}
                      onClick={this.setPasswordVisibility}
                      alt="register-hidden"
                    />
                  </div>
                  <div className="form-group">
                    <label className="input-label" htmlFor="password_confirm">
                      Confirm Password
                    </label>
                    <input
                      type={passwordHidden ? "password" : "text"}
                      className="form-control radius border-black fs-poppins"
                      name="password_confirm"
                      value={password_confirm}
                      onChange={this.onChange}
                      required
                    />
                    <img
                      className="toggle-password"
                      src={`/assets/images/${
                        passwordHidden ? "visible" : "hidden"
                      }.png`}
                      onClick={this.setPasswordVisibility}
                      alt="register-hidden"
                    />
                  </div>
                  <div className="form-group legalTxt">
                    <p className="fs-poppins">
                      By clicking Sign up you agree to our terms and you have
                      read our{" "}
                      <a className="fw-bold" href="#">
                        Terms and Conditions
                      </a>
                    </p>
                  </div>
                  <button
                    type="submit"
                    className="button w-100 radius border-black"
                  >
                    Sign Up
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  signUp,
  setModal: setWeerdyModal,
};

export default connect(mapStateToProps, mapActionsToProps)(RegisterModal);
