import React,{useState} from 'react';

import { APIRequest } from '../lib/apiRequest';
import { setToast } from "../lib/toast";

const Explain = () =>
{
  const [user,setUser] = useState({InputName: '',InputEmail: '',InputEnquiry: ''});
  function handleSubmit(e) {
	  e.preventDefault();
	  try {
		  APIRequest({
			  method: 'POST',
			  url: `/contact`,
			  data: {
				  name: user.InputName,
				  email: user.InputEmail,
				  enquiry: user.InputEnquiry
			  }
		  }).then(() => {
			  setToast('success', 'Your enquiry has been sent');
		  });
	  }catch(error) {
		  setToast('warning', 'There is a problem!');
	  }
  }
  
  function eventHandler(e)
  {
	  const {name, value} = e.target;
	  
	  switch(name) {
		  case 'InputName':
			setUser({...user, InputName: value})
		  break;
		  case 'InputEmail':
			setUser({...user, InputEmail: value})
		  break;
		  case 'InputEnquiry':
			setUser({...user, InputEnquiry: value})
		  break;
		  default:
		  break;
	  }
  }
  
  return (
    <section className="py-24 sm:py-12">
      <div className="container-smart">
        <div className="row row-eq-height">
          <div className="col-lg-4 col-md-4 col-sm-12 column">
            <div className="box-card text-center relative">
              <div className="v-center detail">
                <img className="ee-image" src="/assets/images/weerdy-details.png" alt="image " />
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 column sm:mt-8">
            <div className="box-card weerdy-detail">
                <h2 className="fw-ebold mb-8 text-center">Weerdy Explained</h2>
                <div className="scrollable p-3">
                  <h4 className="fw-ebold color-green">Concept Overiew</h4>
                  <p>Weerdy simplifies the online social experience in a fun and unique way. Weerdy allows users to communicate to the world for 15 seconds (i.e., using 15 words). During that 15 seconds, a user’s message will be the only thing that other users see on the website until the 15 seconds elapses and another user’s message is displayed.</p>
                  <h4 className="fw-ebold color-green">Philosophy</h4>
                  <p>Social media has become over-saturated and unfocused. Weerdy seeks to provide a service that slows the franticness of social media, allowing users to digest one thought at a time. This service gives the average person the opportunity to speak to the world and be heard without distraction.</p>
                  <h4 className="fw-ebold color-green mb-8 sm:mb-4">Worthwhile Vocabulary</h4>
                  <ul>
                    <li>Weerdy – A website that allows users to generate 15-word messages to be displayed for 15 seconds on the site’s homepage without disruption.</li>
                    <li>Weerd – A 15-word message to be featured on the site’s homepage (i.e., as the only visible message) for 15 seconds.</li>
                    <li>Weerds of the Day – A reference meant to highlight the top Weerds that received the most “likes,” “shares,” “views,” or “dislikes” in a 24-hour timeframe.</li>
                    <li>World Wide Weerd – The site’s main page where anyone from anywhere in the world can have their 15-word Weerds displayed for 15 seconds.</li>
                    <li>Curated Weerd Groups – Categorical groups (e.g., sports, politics, comedy ) that allow users to share 15-word Weerds amongst a smaller, more focused group of people.</li>
                    <li>Custom Weerd Groups – Weerd groups that are created and managed by the site’s users.</li>
                    <li>Free Users – Users who use the site for free.</li>
                    <li>Purchasable Content – Content that free users may purchase on a single-use basis.</li>
                    <li>Premium Users – Users that get special perks as part of being a premium member.</li>
                    <li>Weerd Timer – A countdown timer that shows how long before the next Weerd appears.</li>
                  </ul>
				  <p></p>
				  <h4 className="fw-ebold color-green mb-8 sm:mb-4">Contact Us</h4>
				  <form onSubmit={handleSubmit}>
					  <div className="form-group">
						<label htmlFor="exampleInputEmail1">Name</label>
						<input type="text" className="form-control" name="InputName" onChange={eventHandler} value={user.InputName} placeholder="Enter Name" required/>
					  </div>
					  <div className="form-group">
						<label htmlFor="exampleInputEmail1">Email address</label>
						<input type="email" className="form-control" name="InputEmail" onChange={eventHandler} value={user.InputEmail} placeholder="Enter email" required/>
					  </div>
					  <div className="form-group">
						<label htmlFor="exampleInputEmail1">Enter your enquiry</label>
						<textarea className="form-control" name="InputEnquiry" onChange={eventHandler} value={user.InputEnquiry} placeholder="Enter enquiry" required></textarea>
					  </div>
					  <button type="submit" className="btn btn-primary" id="InputEnquirySubmit">Submit</button>
					</form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Explain;

