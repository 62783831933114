import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { stateMappings } from "../redux/stateMappings";
import { userRemove, signOut } from "../redux/auth/thunks";
import { setWeerdyModal } from "../redux/modal/thunks";

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { setModal, deleteUser, currentUser, signOut } = this.props;
    const { username, plan } = currentUser || {};

    return (
      <SectionContainer className="py-24 sm:py-12">
        <div className="container-smart">
          <div className="row row-eq-height">
            <div className="col-lg-4 col-md-4 col-sm-12 column">
              <div className="box-card text-center relative">
                <div className="v-center detail">
                  <img
                    className="ee-image"
                    src="/assets/images/settting.jpg"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 column sm:mt-8">
              <div className="box-card weerdy-detail">
                <h2 className="fw-ebold mb-4 text-center">Settings</h2>
                <div className="p-3">
                  <ul className="setting">
                    <li>
                      <h5 className="fw-ebold color-green">User Name</h5>
                      <p className="m-0 ">{username}</p>
                      <img
                        onClick={() => setModal("usernameEditModalOpen")}
                        src="/assets/images/edit.svg"
                        alt="edit-icon"
                      />
                    </li>
                    <li>
                      <h5 className="fw-ebold color-green">Password</h5>
                      <p className="m-0 ">********************</p>
                      <img
                        onClick={() => setModal("passwordChangeModalOpen")}
                        src="/assets/images/edit.svg"
                        alt="edit-icon"
                      />
                    </li>
                    <li>
                      <h5 className="fw-ebold color-green">Current Plan</h5>
                      <p className="m-0 plan">
                        {plan}
                        {plan === "free" && (
                          <a
                            className="float-right fw-bold color-red"
                            onClick={() => setModal("premiumModalOpen")}
                            href="#"
                          >
                            Premium
                          </a>
                        )}
                      </p>
                    </li>
                    <li>
                      <h5 className="fw-ebold color-green">Preferences</h5>
                      <p className="m-0 ">Notifications</p>
                      <div className="togglebutton r" id="button-1">
                        <input type="checkbox" className="checkbox" />
                        <div className="knobs"></div>
                        <div className="layer"></div>
                      </div>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="button red radius border-black"
                        onClick={() => deleteUser(currentUser)}
                      >
                        Deactivate Account
                      </button>
                      <button
                        className="button red radius border-black"
                        onClick={() => signOut(currentUser)}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    currentUser: sm.user,
  };
};

const mapActionsToProps = {
  setModal: setWeerdyModal,
  deleteUser: userRemove,
  signOut,
};

export default connect(mapStateToProps, mapActionsToProps)(Settings);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;
  }
`;
