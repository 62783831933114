//This file holds the API calls that hit the /user route for DRY purposes
import { APIRequest, getToken } from '../../lib/apiRequest';

const currentUser = getToken("user");

export const getWeerds = async () =>
  APIRequest.request({
    method: 'GET',
    url: `/weerds`
  });

  export const getMyWeerds = async () =>

  APIRequest.request({
    method: 'GET',
    url: `/myweerds/${currentUser._id}`
  });

export const postWeerd = async (weerd) =>
  APIRequest.request({
    method: 'POST',
    url: `/weerd`,
    data: {
      ...weerd,
    }
  });

export const putWeerd = async (id, weerd) =>
  APIRequest.request({
    method: 'PUT',
    url: `/weerd/${id}`,
    data: {
      ...weerd,
    }
  });

export const deleteWeerd = async (id) =>
  APIRequest.request({
    method: 'DELETE',
    url: `/weerd/${id}`,
  });

export const getTypeWeerd = async (type) =>
  APIRequest.request({
    method: 'GET',
    url: `/typed-weerds/${type}`,
  });