import {
  SET_MODAL,
} from './actionEnum';

export const setModal = (modal) => ({
  type: SET_MODAL,
  payload: modal
});



