import React, { Component } from 'react';
import { APIRequest } from '../lib/apiRequest';

class EmailVerify extends Component {
	constructor(props) {
		super(props);
		const queryParams = new URLSearchParams(window.location.search);
		const token = queryParams.get('v');
		APIRequest.request({
			method: 'PUT',
			url: `/user-verify/${token}`
		});
	}
	render() {
		return (
			<div className="col-md-12 col-sm-12 sm:mt-8 column">
              <div className="box-card weerdy-detail">
                  <h3 className="fw-ebold mb-8 text-center">Your account has been verified!</h3>
				  <p className="text-center">Login to continue</p>
			  </div>
			</div>
		);
	}
}

export default EmailVerify;