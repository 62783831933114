import React, { Component } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { stateMappings } from '../redux/stateMappings';
import { signUp, userEdit } from '../redux/auth/thunks';
import { setWeerdyModal } from '../redux/modal/thunks';
import { setToast } from '../lib/toast';

Modal.setAppElement('#root');

class UserNameEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      isEdit: false,
    };
  }

  onChange = ({ target: { name, value } }) =>
    this.setState(state => ({
      ...state,
      [name]: value,
    }));

  handleSubmit = async (e) => {
    e.preventDefault();

    const { username } = this.state;
    const { editUser, setModal, currentUser } = this.props;

    if (!username) {
      setToast('error', "Please input User name.");
      return;
    }

    try {
      await editUser({
        _id: currentUser._id,
        username,
      });
    } catch (error) {
      return;
    }

    this.displayEdit();
    setModal('usernameEditModalOpen');
  };

  displayEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit,
    });
  }

  render() {
    const {
      open,
      isOpen,
      customStyles,
      isAuthenticated,
      currentUser,
    } = this.props;

    const { username, isEdit } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={(e) => open(e, "usernameEditModalOpen")}
        closeTimeoutMS={500}
        style={customStyles}
        className="modal fade show"
      >
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
          <div className="modal-content crudModal p-8 sm:p-4 position-relative">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <img className="action-sure-image" src="./images/edit.svg" alt="" /> <h5 className="fw-bold">Edit Your User Name</h5>
                </div>
                <div className={`col-lg-12 text-center mt-16 sm:mt-8 editable-cell${isEdit ? ' editing' : ''}`}>
                  <input name="username" value={username === null ?  currentUser.username : username } onChange={this.onChange} className="dotted-input editable-input" type="text" placeholder="" readOnly={!isEdit}/>
                </div>
              </div>
              <DivContainer className="row d-flex justify-content-between mt-12 sm:mt-8">
                <a href="#" onClick={(e) => open(e, "usernameEditModalOpen")} className="button red radius border-black fs-poppins">Cancel</a>
                {!isEdit && <a href="#" onClick={this.displayEdit} className="button green radius border-black fs-poppins edit-button">Edit</a>}
                {isEdit && <button type="submit" className="button green radius border-black fs-poppins save-button show">Save</button>}
              </DivContainer>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    currentUser: sm.user,
  };
};

const mapActionsToProps = {
  signUp,
  setModal: setWeerdyModal,
  editUser: userEdit,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(UserNameEditModal);

const DivContainer = styled.div`
  .save-button {
    height: 36px;
    font-size: 16px;
    padding: 4px 24px;
  }
`
