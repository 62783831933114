import {
  SET_WEERDS,
  ADD_WEERD,
  EDIT_WEERD,
  REMOVE_WEERD,
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
  GET_WEERD,
  SET_MY_WEERDS
} from './actionEnum';


export const setWeerds = (weerds) => ({
  type: SET_WEERDS,
  payload: weerds,
});

export const addWeerd = (weerd) => ({
  type: ADD_WEERD,
  payload: weerd,
});

export const editWeerd = (weerd) => ({
  type: EDIT_WEERD,
  payload: weerd,
});

export const removeWeerd = (weerd) => ({
  type: REMOVE_WEERD,
  payload: weerd,
});

export const setLoadingStatus = (candidate) => ({
  type: SET_LOADING_STATUS,
  payload: candidate,
});

export const setErrorMessage = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: message,
});

export const getWeerdByType = (weerd) => ({
	type: GET_WEERD,
	payload: weerd
});

export const setMyWeerds = (weerds) => ({
  type: SET_MY_WEERDS,
  payload: weerds,
});