import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const TermsModal = ({
  open,
  isOpen,
  customStyles,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={(e) => open(e, "termsModalOpen")}
      closeTimeoutMS={500}
      style={customStyles}
      className="modal fade show"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="p-8 sm:p-4">
            <div className="p-8 sm:p-4 border-green radius-md">
              <h2 className="fw-ebold text-center">Terms And Conditions</h2>
              <ul className="scrollable fs-poppins">
                <li>Weerdy is a free to use platform, that only charges when premium options are selected.</li>
                <li>As users of Weerdy, you are responsible for your use of the services and for any content you provide, including compliance with applicable laws, rules, and regulations. You should only provide content that you are comfortable sharing with others.</li>
                <li>Weerdy allows users to express themselves constructively, enabling them to connect with others, build friendships, and grow new online communities.</li>
                <li>Weerdy’s products are provided “as is,” and we make no guarantees that Weerdy will be safe, secure, or error-free or that it will function without disruptions, delays, or imperfections.</li>
                <li>Weerdy cannot predict when issues might arise with our services. Accordingly, our liability shall be limited to the fullest extent permitted by applicable law, and under no circumstance will we be liable to you for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages.</li>
                <li>Words or messages posted on Weerdy become the express property of Weerdy.</li>
                <li>Weerdy is a place for users to share fun and inciteful ideas, thoughts, and comments. Users who post harmful, profane, or cruel messages on the site will be banned.</li>
                <li>Weerdy does not sell your personal data to advertisers, and we do not share information that directly identifies you (such as your name, email address, or other contact information) with advertisers unless you give us specific permission.</li>
                <li>Our services are ever-evolving. As such, the services may change from time to time at our discretion. We may stop (permanently or temporarily) providing the services or any features within the services to you or users generally. Weerdy also retains the right to create limits on use and storage at our sole discretion at any time.</li>
              </ul>
            </div>
            <div className="text-center mt-4 modal-actions">
              <button type="button" className="button green radius border-black" onClick={(e) => open(e, "termsModalOpen")}>Accept Terms And Conditions</button>
              <button type="button" className="button green radius border-black" onClick={(e) => open(e, "termsModalOpen")} data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TermsModal;
