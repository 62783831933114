import React,{useEffect,useState,useRef} from 'react';
import styled from 'styled-components';
import moment from "moment";
import { connect } from 'react-redux';

import { APIRequest } from '../lib/apiRequest';
import environment from '../environments/environment.dev';
import { stateMappings } from '../redux/stateMappings';
import { setToast } from '../lib/toast';

const UserProfile = (props) => {
	var [username, setUsername] = useState("");
	var [email, setEmail] = useState("");
	var [userid, setUserid] = useState("");
	var [userplan, setUserPlan] = useState("");
	var [profileImage, setProfileImage] = useState("");
	var [activeWeerd, setActiveWeerd] = useState([]);
	var [follow, setFollow] = useState([]);
	var [totalLikes, setTotalLikes] = useState(0);
	var [totalDislikes, setTotalDislikes] = useState(0);
	var [isLiked, setIsLiked] = useState(false);
	var [isDisliked, setIsDisliked] = useState(false);
	
	const query = window.location.search.substring(1);
	var vars = query.split("&");
	var pair = vars[0].split("=");
	const likeRef = React.createRef();
	const dislikeRef = React.createRef();
	
	useEffect(() => {
		try {
			APIRequest.request({
				method: 'POST',
				url: `/get-user-profile`,
				data: {
					profile_user_id: pair[1],
					current_user_id: props.user._id
				}
			}).then(result => {
				setUsername(result.data.user.username);
				setEmail(result.data.user.email);
				setUserid(result.data.user._id);
				setUserPlan(result.data.user.plan);
				setProfileImage(result.data.user.profile_img);
				setActiveWeerd(result.data.weerd.filter((e) => e.is_anonymous !== true).sort(function (a, b) {
					return new Date(b.createdAt) - new Date(a.createdAt);
				}));
				
				setFollow(result.data.follow);
			});
		} catch (error) {
			console.log(error.message);
		}
		
		try {
			APIRequest.request({
				method: 'GET',
				url: `/get-like-dislike/`+pair[1],
			}).then(result => {
				setTotalLikes(result.data.like.length);
				result.data.like.filter((id) => id.likerId === props.user._id ? setIsLiked(true) : '');
				setTotalDislikes(result.data.dislike.length);
				result.data.dislike.filter((id) => id.dislikerId === props.user._id ? setIsDisliked(true) : '');
			});
		} catch (error) {
			setToast('warning',error.message);
		}
	},[]);
	
	const chatHandler = () => {
		const { _id } = props.user || {};
		APIRequest.request({
			method: 'GET',
			url: `/find-chat/`+_id+`/`+userid,
			data: {

			}
		}).then(result => {


			if(result.data != undefined){
				window.location.href = '/chat/?id='+result.data._id;
			}else {
				addNewChat();
			}

		});
	}

	const addNewChat = () => {

		const { _id } = props.user || {};
		APIRequest.request({
			method: 'POST',
			url: `/chat`,
			data: {
				senderId: _id,
				receiverId: userid,
			}
		}).then(result => {
			if(result.data != undefined){
				//console.log(" ID "+result.data._id)
				window.location.href = '/chat/?id='+result.data._id;
			}	
		});
	}

	const followHandler = () => {
		const { _id } = props.user || {};
		APIRequest.request({
			method: 'POST',
			url: `/follow-user`,
			data: {
				followId: userid,
				followerId: _id,
				created_at: new Date()
			}
		}).then(result => {
			setToast('success', 'Success!');
		    window.location.reload(false);
		});
	}
	
	const addLike = () => {
		var {isAuthenticated} = props;
		
		if(isAuthenticated) {
			var like = likeRef.current.classList.contains('green');
			
			if(!like) {
				var total = totalLikes+1;
				likeRef.current.classList.add('green');
				if(dislikeRef.current.classList.contains('red')) {
					dislikeRef.current.classList.remove('red');
					var total2 = totalDislikes-1;
					setTotalDislikes(total2);
				}
			}else {
				var total = totalLikes-1;
				likeRef.current.classList.remove('green');
			}
			setTotalLikes(total);
			
			try {
				APIRequest.request({
					method: 'POST',
					url: `/add-like`,
					data: {
						likeId: pair[1],
						likerId: props.user._id
					}
				}).then(result => {
					console.log(result.data);
				});
			} catch (error) {
				setToast('warning',error.message);
			}
		}else {
			setToast('warning','You need to login to do this action!');
		}
	}
	
	const addDislike = () => {
		var {isAuthenticated} = props;
		if(isAuthenticated) {
			var dislike = dislikeRef.current.classList.contains('red');
			if(!dislike) {
				var total2 = totalDislikes+1;
				dislikeRef.current.classList.add('red');
				if(likeRef.current.classList.contains('green')) {
					likeRef.current.classList.remove('green');
					var total = totalLikes-1;
					setTotalLikes(total);
				}
			}else {
				var total2 = totalDislikes-1;
				dislikeRef.current.classList.remove('red');
			}
			setTotalDislikes(total2);
			
			try {
				APIRequest.request({
					method: 'POST',
					url: `/add-dislike`,
					data: {
						dislikeId: pair[1],
						dislikerId: props.user._id
					}
				}).then(result => {
					console.log(result.data);
				});
			} catch (error) {
				setToast('warning',error.message);
			}
		}else {
			setToast('warning','You need to login to do this action!');
		}
	}
	
	const { _id, plan } = props.user || {};
	return (
		<SectionContainer className="py-24 sm:py-12">
		  <div className="container-smart">
			<div className="row row-eq-height">
			  <div className="col-lg-4 col-md-4 col-sm-12 column">
				<div className="box-card text-center relative">
				  <div className="v-center detail">
					{profileImage ? <img className="ee-image" src={environment.img_api+"public/userImages/"+profileImage} alt="profile-ee" /> : <img className="ee-image" src="/assets/images/yourprofile.png" alt="profile-ee" />}
				  </div>
				</div>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 column sm:mt-8">
				  <div className="box-card weerdy-detail">
					  <h2 className="fw-ebold mb-4 text-center">Profile</h2>
					  <div className="p-3">
						<ul className="setting">
						  <li>
							<h5 className="fw-ebold color-green">User Name</h5>
							<p className="m-0 ">{username}
						
							{plan === "premium" && userplan == 'premium' && _id != userid && (
                          <a
                            className="fw-bold color-red"
                        
                            href="#" onClick={chatHandler}
                          >
                            <strong><i class="fa fa-comment" style={{"fontSize": "30px","margin-left": "10px"}} title="Chat"></i></strong>
                          </a>
                        )}
							
							</p>
						  </li>
						  <li>
							<h5 className="fw-ebold color-green">Email Address</h5>
							<p className="m-0 ">{email}</p>
						  </li>
						  {plan === "premium" && userid != _id ?
						  follow.length > 0 ?
						  <li><input type="submit" className="btn btn-primary" onClick={followHandler} value="Unfollow"/></li> : <li><input type="submit" className="btn btn-primary" onClick={followHandler} value="Follow"/></li> : ""}
						</ul>
						<h4>Share this user: </h4>
						<div>
						<a href={"https://api.whatsapp.com/send?text=https://weerdy.com/user-profile/?user="+pair[1]} target="_blank" id="share-wa" className="sharer"><img src="/assets/images/whatsapp.png"/></a>
						<a href={"https://www.facebook.com/sharer/sharer.php?u=https://weerdy.com/user-profile/?user="+pair[1]} id="share-fb" className="sharer" target="_blank"><img src="/assets/images/facebook.png"/></a>
						<a href={"https://twitter.com/intent/tweet?url=https://weerdy.com/user-profile/?user="+pair[1]} id="share-tw" className="sharer" target="_blank"><img src="/assets/images/twitter.png"/></a>
						</div>
						<div>
						{totalLikes}<button className={isLiked ? "btn green" : "btn"} id="green" onClick={() => {addLike()}} ref={likeRef}><i className="fa fa-thumbs-up fa-lg" aria-hidden="true"></i></button>
						{totalDislikes}<button className={isDisliked ? "btn red" : "btn"} id="red" onClick={() => {addDislike()}} ref={dislikeRef}><i className="fa fa-thumbs-down fa-lg" aria-hidden="true"></i></button>
						</div>
					  </div>
				  </div>
				</div>
				<table width={1200} border="1" cellPadding={4} cellSpacing={4}>
					<thead>
					  <tr>
						<th>Title</th>
						<th>Category</th>
						<th>Likes</th>
						<th>Dislikes</th>
						<th>Rights</th>
						<th>Shares</th>
						<th>Views</th>
						<th>Created At</th>
					  </tr>
					</thead>
					<tbody>
					  {activeWeerd.map((item, i) => (
						<tr key={i}>
						  <td><div className="fw-ebold focus-editing" name="main-weerd" dangerouslySetInnerHTML={{__html: item.title}} style={{height: "150px"}} readOnly></div></td>
						  <td>{item.category}</td>
						  <td>{item.likes.length}</td>
						  <td>{item.dislikes.length}</td>
						  <td>{item.rights.length}</td>
						  <td>{item.shares.length}</td>
						  <td>{item.total_views}</td>
						  <td>{moment(item.createdAt).format("LL")}</td>
						</tr>
					  ))}
					</tbody>
				  </table>
			</div>
		  </div>
		</SectionContainer>
	);
}

//export default UserProfile;
const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);

  return {
    user: sm.user,
	isAuthenticated: sm.isAuthenticated,
  };
};

const mapActionsToProps = {
  
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(UserProfile);

const SectionContainer = styled.section`
  .plan {
    text-transform: capitalize;

    span {
      cursor: pointer;

      &:hover {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
        color: inherit;
      }
    }
  }
`