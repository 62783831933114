import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import Explain from './pages/Explain';
import Donate from './pages/Donate';
import Profile from './pages/Profile';
import Landing from './pages/Landing';
import Create from './pages/Create';
import Settings from './pages/Settings';
import Categories from './pages/Categories';
import DailyWeerds from './pages/DailyWeerds';
import MyWeerd from './pages/MyWeerd';
import Chat from './pages/Chat/Chat';
import EmailVerify from './pages/EmailVerify';
import Weerd from './pages/Weerd';
import UserProfile from './pages/UserProfile';
import { setToast  } from './lib/toast';
import { stateMappings } from "./redux/stateMappings";

const AuthRoute = ({
  component,
  isAuthenticated,
  ...rest
}) => {
  const routeComponent = (props) => {
    if (isAuthenticated) {
      return React.createElement(component, props);
    } else {
      // setToast('warning', 'Authentication is required.');
      return (
        <Redirect to={{pathname: '/'}}/>
      );
    }
  };
  return <Route {...rest} render={routeComponent}/>;
};

// const AdminRoute = ({
//   component,
//   isAuthenticated,
//   isAdmin,
//   ...rest
// }) => {
//   const routeComponent = (props) => {
//     if (isAuthenticated && isAdmin) {
//       return React.createElement(component, props);
//     } else {
//       return (
//         <Redirect to={{pathname: '/'}}/>
//       );
//     }
//   };
//   return <Route {...rest} render={routeComponent}/>;
// };

const Router = (props) => (
  <BrowserRouter>
    <Switch >
      <Route exact path="/" component={true ? Landing : Explain} />
      <Route path="/landing" component={Landing} />
	  <Route path="/weerd" component={Weerd} />
      <Route path="/categories" component={Categories} />
      <Route path="/day" component={DailyWeerds} />
      <Route path="/explained" component={Explain} />
      <Route path="/donate" component={Donate} />
	  <Route path="/email-verify" component={EmailVerify} />
	  <Route path="/user-profile" component={UserProfile} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/myweerd" component={MyWeerd} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/chat" component={Chat} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/settings" component={Settings} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/profile" component={Profile} isAuthenticated={props.isAuthenticated} />
      <AuthRoute path="/create" component={Create} isAuthenticated={props.isAuthenticated} />
    </Switch>
  </BrowserRouter>
);

const mapStateToProps = ( state ) => {
  const sm = stateMappings(state);
  return {
    isAuthenticated: sm.isAuthenticated,
    isAdmin: sm.isAdmin,
  };
};

export default connect(
  mapStateToProps,
  undefined,
)(Router);
