import React, { Component, Fragment } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { connect } from "react-redux";
import axios from "axios";
import StripePyamentModal from "./StripePyamentModal";
import { setWeerdyModal } from "../redux/modal/thunks";
import { stateMappings } from "../redux/stateMappings";
import { setToast } from "../lib/toast";

import { userEdit } from "../redux/auth/thunks";

import environment from "../environments/environment.dev";

import ReactDOM from "react-dom";
const PayPalButton = window.paypal.Buttons.driver("react", {
  React,
  ReactDOM,
});
Modal.setAppElement("#root");

class PaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment: "master",
    };
  }

  setPaymentMethod = (method) => {
    this.setState({
      payment: method,
    });
  };

  onChange = ({ target: { name, value } }) =>
    this.setState((state) => ({
      ...state,
      payment: value,
    }));

  createOrder = (data, actions) => {
    const { plan } = this.props;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: plan === "month" ? 19.99 : 199.99,
          },
        },
      ],application_context: {
                      shipping_preference: 'NO_SHIPPING',
                  },
    });
  };
  onApprove = (data, actions) => {
    const { setToast, user, userEdit, plan } = this.props;

    return actions.order.capture().then((details) => {
      //const name = details.payer.name.given_name;
      let now = new Date();
      userEdit({
        ...user,
        //username: "ahmed",
        plan: "premium",
        plan_expires:
          plan === "year"
            ? new Date(now.setMonth(now.getMonth() + 12))
            : new Date(now.setMonth(now.getMonth() + 1)),
      }).then(()=> {
		  window.location.href = `${environment.base_url}/profile`;
		  setToast("success", "your account has been updated!");
	  });
    });
  };

  render() {
    const {
      open,
      isOpen,
      StripePyamentModalOpen,
      setModal,
      customStyles,
      plan,
    } = this.props;
    const { payment } = this.state;

    return (
      <Fragment>
        <Modal
          isOpen={isOpen}
          onRequestClose={(e) => open(e, "paymentModalOpen")}
          closeTimeoutMS={500}
          style={customStyles}
          className="modal fade show"
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content onboardingModal">
              <div className="row">
                <div className="col-lg-12 mb-8 text-center">
                  <h5 className="fw-bold">Select Your Payment Method</h5>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12  my-auto text-center sm:hidden">
                  <img
                    className="ee-image"
                    src="/assets/images/ee-image.png"
                    alt="image"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <form className="pay-methods">
                    <div
                      className="relative checked"
                      onClick={() => setModal("StripePyamentModalOpen")}
                    >
                      {/* <input
                        type="radio"
                        name="master"
                        id="master-card"
                        value="master"
                        onChange={this.onChange}
                        checked={payment === "master"}
                      /> */}
                      <label
                        className="button white w-100 radius border-black"
                        htmlFor="master-card"
                      >
                        Credit Card/Debit Card
                      </label>
                      <img src="/assets/images/mc.svg" alt="" />
                    </div>
                    <div className="relative">
                      {/* <input
                        type="radio"
                        name="paypal"
                        id="paypal"
                        value="paypal"
                        onChange={this.onChange}
                        checked={payment === "paypal"}
                      /> */}
                      {/* <label
                        className="button white w-100 radius border-black"
                        for="paypal"
                      >
                        Paypal
                      </label>
                      <img src="/assets/images/pp.svg" alt="" /> */}
                      <PayPalButton
                        createOrder={(data, actions) =>
                          this.createOrder(data, actions)
                        }
                        onApprove={(data, actions) =>
                          this.onApprove(data, actions)
                        }
                      />
					  
                    </div>

                    {/* <div className="relative">
                      <input
                        type="radio"
                        name="visa"
                        id="visa"
                        value="visa"
                        onChange={this.onChange}
                        checked={payment === "visa"}
                      />
                      <label
                        className="button white w-100 radius border-black"
                        for="visa"
                      >
                        Visa
                      </label>
                      <img src="/assets/images/vi.svg" alt="" />
                    </div> */}
                    {/* <div className="relative">
                      <input
                        type="radio"
                        name="express"
                        id="american-express"
                        onChange={this.onChange}
                        value="express"
                        checked={payment === "express"}
                      />
                      <label
                        className="button white w-100 radius border-black"
                        for="american-express"
                      >
                        American Express
                      </label>
                      <img src="/assets/images/cc.svg" alt="" />
                    </div> */}
                    {/* <button
                      onClick={() => setModal("StripePyamentModalOpen")}
                      data-toggle="modal"
                      data-target="#ConfirmationModal"
                      data-dismiss="modal"
                      type="button"
                      className="button green w-100 radius border-black mt-4 "
                    >
                      Next
                    </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <StripePyamentModal
          open={open}
          isOpen={StripePyamentModalOpen}
          customStyles={customStyles}
          plan={plan}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const sm = stateMappings(state);

  return {
    isAuthenticated: sm.isAuthenticated,
    StripePyamentModalOpen: sm.StripePyamentModalOpen,
    user: sm.user,
  };
};
const mapActionsToProps = {
  setModal: setWeerdyModal,
  setToast,
  userEdit,
};
export default connect(mapStateToProps, mapActionsToProps)(PaymentModal);
const DivContainer = styled.div`
  .relative {
    margin-top: 1.5rem;
  }
`;
